import { S3 } from "aws-sdk";

// src/config.js
const config = {
  cognito: {
    USER_POOL_ID: 'us-east-1_q5PyxojaQ',
    APP_CLIENT_ID: '4bb747v13n9jm0i1s4snhsdtbs',
    REGION: 'us-east-1',
  },
  APPSYNC_URL: 'https://api-dev.joboomi.app/graphql',
  //REACT_APP_S3_BUCKET_NAME: 'dev-user-avatars',
  //REACT_APP_AWS_REGION: 'us-east-1',
};

export const GOOGLE_CLIENT_ID = '797551660409-124mfui79o8ldd7dbdqnpbsrflc2icns.apps.googleusercontent.com';

// src/config.js
export const STATUS_OPTIONS = [
  'Saved',
  'Applied',
  'Interviewing',
  'Offer Received',
  'Rejected',
  'Accepted',
  'Withdrawn'
];

export default config;
