import { useQuery, gql, useApolloClient } from '@apollo/client';

export const GET_ALERTS = gql`
  query GetAlerts {
    getAlerts {
      id
      alertType
      alertTitle
      alertMessage
      alertTarget
      createdAt
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: ID!) {
    deleteAlert(id: $id) {
      id
      alertTitle
    }
  }
`;

export const DELETE_ALL_ALERTS = gql`
  mutation DeleteAllAlerts {
    deleteAllAlerts {
      success
      message
    }
  }
`;

export const UPDATE_USER_EMAIL_AUTH = gql`
  mutation UpdateUserEmailAuth($input: UpdateUserEmailAuthInput!) {
    updateUserEmailAuth(input: $input) {
      emailAuth {
        gmail {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
        outlook {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
        yahoo {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
      }
    }
  }
`;

export const GET_JOB_OPPORTUNITY_BY_ID = gql`
  query getJobOpportunityById($id: ID!) {
    getJobOpportunityById(id: $id) {
      id
      company
      positionTitle
      personalNote
      personalNoteUpdatedAt
      createdAt
      updatedAt
      owner
      # Add any other fields you need here
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      firstName
      lastName
      email
      phone
      notes
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      firstName
      lastName
      email
      phone
      notes
    }
  }
`;

export const UPDATE_PROCESS_STATUS = gql`
  mutation updateProcessStatus($id: ID!, $processStatus: String!) {
    updateProcessStatus(id: $id, processStatus: $processStatus) {
      id
      processStatus
      updatedAt
    }
  }
`;

export const UPDATE_JOB_OPPORTUNITY_GROUP = gql`
  mutation updateJobOpportunityGroup($id: ID!, $group: String!) {
    updateJobOpportunityGroup(id: $id, group: $group) {
      id
      group
      updatedAt
    }
  }
`;

export const UPDATE_JOB_OPPORTUNITY_ACK_EMAIL_THREAD_ID = gql`
  mutation removeReviewEmailByThreadId($id: ID!, $threadId: String!) {
    removeReviewEmailByThreadId(id: $id, threadId: $threadId) {
      id
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      filterGmailThreadIds
      updatedAt
    }
  }
`;

export const UPDATE_JOB_OPPORTUNITY_FILTER_GMAIL_THREAD_IDS = gql`
  mutation updateJobOpportunityAddFilterGmailThreadId($id: ID!, $threadId: String!) {
    updateJobOpportunityAddFilterGmailThreadId(id: $id, threadId: $threadId) {
      id
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      filterGmailThreadIds
      updatedAt
    }
  }
`;



export const UPDATE_PERSONAL_NOTE = gql`
  mutation updateJobOpportunityPersonalNote($id: ID!, $personalNote: String!) {
    updateJobOpportunityPersonalNote(id: $id, personalNote: $personalNote) {
      id
      personalNote
      personalNoteUpdatedAt
      updatedAt
    }
  }
`;

export const GET_RESUME_FEEDBACK = gql`
  query GetResumeFeedback($resumeId: ID!, $jobId: ID!) {
    getResumeFeedback(resumeId: $resumeId, jobId: $jobId) {
      jobKeywords
      matchKeywords
      suggestedKeywords
      goodThings
      notSoGoodThings
      recommendations
      experienceFeedback
      experienceMatch
      score
    }
  }
`;

export const GET_INTERVIEW_PREP = gql`
  query GetInterviewPrep($resumeId: ID!, $jobDetails: String!, $interviewer: String!) {
    getInterviewPrep(resumeId: $resumeId, jobDetails: $jobDetails, interviewer: $interviewer) {
      talkingPoints
      interviewQuestions
      interviewer
    }
  }
`;

export const GET_COVER_LETTER = gql`
  query GetCoverLetter($resumeId: ID!, $jobDetails: String!, $tone: String!) {
    getCoverLetter(resumeId: $resumeId, jobDetails: $jobDetails, tone: $tone) {
      coverLetterContent
    }
  }
`;

export const GET_PRE_SIGNED_URL = gql`
  query GetPreSignedUrl($title: String!) {
    getPreSignedUrl(title: $title) {
      uploadURL
      s3Key
    }
  }
`;

export const CREATE_RESUME = gql`
  mutation CreateResume($input: CreateResumeInput!) {
    createResume(input: $input) {
      id
      title
      description
      s3Key
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESUMES = gql`
  query GetResumes {
    getResumes {
      id
      title
      description
      s3Key
      owner
      interviewRoles
      skills {
      explicitSkills {
        hardSkills
        softSkills
      }
      implicitSkills
      industries
    }
    workHistory {
      company
      title
      duration
      overview
      responsibilities
    }
    highlights {
      summary
      achievements
      highlights
      education
    }
      resumeStatus
      uploadDate
      createdAt
      updatedAt
    }
  }
`;

export const EXTRACT_CONTENT = gql`
  query ExtractContent($url: String!) {
    extractContent(url: $url) {
      textContent
    }
  }
`;

export const PROCESS_JOB_INFO = gql`
  query ProcessJobInfo($textContent: String!, $save: Boolean!, $url: String) {
    processJobInfo(textContent: $textContent, save: $save, url: $url) {
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications 
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
    }
  }
`;

export const GET_ALL_JOB_OPPORTUNITIES = gql`
  query GetAllJobOpportunities {
    getJobOpportunities(group: "active") {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      personalNote
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      favorite
      outlook
      createdAt
      updatedAt
    }
    bookmarked: getJobOpportunities(group: "bookmarked") {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      favorite
      outlook
      createdAt
      updatedAt
    }
    archived: getJobOpportunities(group: "archived") {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      favorite
      outlook
      createdAt
      updatedAt
    }
    toreview: getJobOpportunities(group: "toReview") {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      reviewEmails {
        provider
        emailAccount
        threadId
        createdAt
        from
        date
        snippet
        subject
      }
      favorite
      outlook
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_JOB_OPPORTUNITY = gql`
  mutation CreateJobOpportunity($input: CreateJobOpportunityInput!) {
    createJobOpportunity(input: $input) {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      favorite
      outlook
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_JOB_OPPORTUNITY = gql`
  mutation UpdateJobOpportunity($input: UpdateJobOpportunityInput!) {
    updateJobOpportunity(input: $input) {
      id
      company
      companyOverview
      positionTitle
      positionOverview
      companyLocation
      workingLocation
      employmentType
      benefits
      responsibilities
      qualifications
      travelRequirements
      website
      salary
      owner
      group
      dateApplied
      postingUrl
      processStatus
      favorite
      outlook
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($id: ID!) {
    deleteResume(id: $id) {
      id
    }
  }
`;

export const DELETE_JOB_OPPORTUNITY = gql`
  mutation DeleteJobOpportunity($id: ID!) {
    deleteJobOpportunity(id: $id) {
      id
    }
  }
`;

export const UPDATE_FAVORITE_STATUS = gql`
  mutation UpdateFavoriteStatus($id: ID!, $favorite: Boolean!) {
    updateFavoriteStatus(id: $id, favorite: $favorite) {
      id
      favorite
      updatedAt
    }
  }
`;

export const UPDATE_OUTLOOK_STATUS = gql`
  mutation UpdateOutlookStatus($id: ID!, $outlook: Int!) {
    updateOutlookStatus(id: $id, outlook: $outlook) {
      id
      outlook
      updatedAt
    }
  }
`;


export const GET_CONTACTS = gql`
  query GetContacts {
    getContacts {
      id
      firstName
      lastName
      title
      company
      email
      phone
      linkedinUrl
      linkedinPhotoUrl
      notes {
        createdAt
        noteContent
      }
      owner
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const UPDATE_USER_LOGIN = gql `
  mutation {
    updateUserLogin {
      id
      email
      lastLogin
      updatedAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      email
      firstName
      lastName
      phoneNumber
      dateOfBirth
      gender
      address {
        street
        city
        state
        zip
        country
      }
      preferredLanguage
      timeZone
      profilePictureUrl
      emailAuth {
        __typename
        gmail {
          __typename
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
        outlook {
          __typename
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
        yahoo {
          __typename
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
      }
    }
  }
`;

export const STORE_GOOGLE_ACCESS_TOKEN = gql`
  mutation StoreGoogleAccessToken($code: String!) {
    storeGoogleAccessToken(code: $code) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      owner
      email
      firstName
      lastName
      phoneNumber
      dateOfBirth
      gender
      address {
        street
        city
        state
        zip
        country
      }
      preferredLanguage
      timeZone
      profilePictureUrl
      subscriptionPlan
      emailAuth {
        gmail {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled
          calEnabled
        }
        outlook {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled 
          calEnabled
        }
        yahoo {
          emailAccount
          access_token
          refresh_token
          last_checked
          enabled 
          calEnabled
        }
      }
    }
  }
`;