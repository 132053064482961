import React from 'react';
import { Accordion, Box, Divider, AccordionSummary, AccordionDetails, IconButton, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
  position: relative; /* Position for the copy icon */
  padding-right: 40px; /* Ensure space for the icon */
  font-size: 0.875rem; /* Smaller font size */
`;

const convertToHtml = (text) => {
  return text
    .replace(/\n\n/g, '</p><p>')
    .replace(/\n/g, '<br />');
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

const FeedbackSections = ({ feedback }) => {
  return (
    feedback && (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h6">Cover Letter</Typography>
        </Divider>
        <StyledBox>
          <Tooltip title="Copy to clipboard">
            <IconButton
              onClick={() => copyToClipboard(feedback.coverLetterContent)}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <div dangerouslySetInnerHTML={{ __html: `<p>${convertToHtml(feedback.coverLetterContent)}</p>` }} />
        </StyledBox>
      </>
    )
  );
};

export default FeedbackSections;