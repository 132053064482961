import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Container, Box, Typography, Avatar, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getCurrentUser } from '../auth';
import timezones from '../data/timezones.json';
import languages from '../data/languages.json';
import SnackbarComponent from './SnackbarComponent';
import AWS from 'aws-sdk';

const ProfileEditForm = ({ user, onSave }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    gender: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    preferredLanguage: '',
    timeZone: '',
    profilePictureUrl: '',
    subscriptionPlan: ''
  });

  const [profilePicture, setProfilePicture] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  const removeTypename = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(removeTypename);
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (key !== '__typename') {
          newObj[key] = removeTypename(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('address')) {
      const addressField = name.split('.')[1];
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
  };

  const handleSaveProfilePicture = async () => {
    if (profilePicture) {
      const s3 = new AWS.S3({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        }),
      });

      const filename = `${getCurrentUser().username}/${profilePicture.name}`;
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: filename,
        Body: profilePicture,
        ContentType: profilePicture.type,
      };

      await s3.upload(params).promise();
      return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${filename}`;
    }
    return formData.profilePictureUrl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const profilePictureUrl = await handleSaveProfilePicture();
    const updatedFormData = { ...formData, profilePictureUrl };
    const cleanedFormData = removeTypename(updatedFormData);
    onSave(cleanedFormData);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          alt={formData.firstName}
          src={formData.profilePictureUrl}
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Typography component="h1" variant="h5">
          {user ? 'Edit Profile' : 'Create Profile'}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Last Name"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="dateOfBirth"
                label="Date of Birth"
                type="date"
                fullWidth
                value={formData.dateOfBirth}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="non-binary">Non-Binary</MenuItem>
                  <MenuItem value="prefer-not-to-say">Prefer Not to Say</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Preferred Language</InputLabel>
                <Select
                  name="preferredLanguage"
                  value={formData.preferredLanguage}
                  onChange={handleChange}
                >
                  {languages.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Time Zone</InputLabel>
                <Select
                  name="timeZone"
                  value={formData.timeZone}
                  onChange={handleChange}
                >
                  {timezones.map((timezone) => (
                    <MenuItem key={timezone.value} value={timezone.value}>
                      {timezone.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="subscriptionPlan"
                label="Subscription Plan"
                fullWidth
                value={formData.subscriptionPlan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address.street"
                label="Street"
                fullWidth
                value={formData.address.street}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="address.city"
                label="City"
                fullWidth
                value={formData.address.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="address.state"
                label="State"
                fullWidth
                value={formData.address.state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="address.zip"
                label="Zip"
                fullWidth
                value={formData.address.zip}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="address.country"
                label="Country"
                fullWidth
                value={formData.address.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
              >
                Upload Profile Picture
                <input
                  type="file"
                  hidden
                  onChange={handleProfilePictureChange}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {user ? 'Update Profile' : 'Create Profile'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileEditForm;