import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, Alert, Box } from '@mui/material';

const GET_PRICING = gql`
  query GetPricing {
    getPricing {
      id
      product {
        id
        name
        description
      }
      unit_amount
      currency
      recurring {
        interval
      }
      description
      price_name
    }
  }
`;

const Subscription = () => {
  const { loading, error, data } = useQuery(GET_PRICING);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Container>
        <Alert severity="error">{error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Pricing
      </Typography>
      <Grid container spacing={3}>
        {data.getPricing.map((price) => (
          <Grid item xs={12} sm={6} md={4} key={price.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {price.price_name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {price.product.description || 'No description available'}
                </Typography>
                <Typography variant="h6" component="div">
                  ${price.unit_amount / 100} {price.currency.toUpperCase()} / {price.recurring ? price.recurring.interval : 'one-time'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Subscription;