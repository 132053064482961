import React, { useState, useEffect } from 'react';
import { Avatar, Drawer, useTheme, useMediaQuery, Box, CssBaseline, Typography, Divider, styled } from '@mui/material';
import { useCustomTheme } from '../ui/CustomThemeProvider';
import DrawerContent from './DrawerContent';
import logo from './assets/JoboomiText.svg';
import SearchBar from './SearchBar';
import BottomNav from './BottomNav';
import HeaderAppBarMobile from './HeaderAppBarMobile';
import UserMenu from './UserMenu';
import RightDrawer from './RightDrawer'; // Import the new RightDrawer component
import { getCurrentUser } from '../auth';
import { useRightDrawer } from '../RightDrawerContext'; // Import the context

const drawerWidth = 240;
const miniDrawerWidth = 80;

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: '2px solid white',
  backgroundColor: '#a793f0',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#c3c3c3',
  },
}));

const MainLayout = ({ children }) => {
  console.log('MainLayout Rendered');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { theme } = useCustomTheme();
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [user, setUser] = useState(null);
  const { isOpen, closeRightDrawer, content } = useRightDrawer(); // Use the context

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpenToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogoutConfirm = () => {
    getCurrentUser().signOut();
    window.location.href = '/signin';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          className="leftDrawer"  // Add a custom class name
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerOpen ? drawerWidth : miniDrawerWidth,
              overflow: 'hidden'
            },
          }}
        >
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', height: '58px', padding: '0 16px', marginLeft: 1 }}>
            <Typography
              onClick={handleDrawerOpenToggle}
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <img src={logo} alt="Joboomi Logo" style={{ height: '35px', width: drawerOpen ? 'auto' : 'auto', clipPath: drawerOpen ? 'none' : 'rect(0 40px 40px 0 )' }} />
            </Typography>
          </Box>
          <SearchBar />
          <Divider sx={{ mt: 2, mb: 3, mr: 2, ml: 2 }} />
          <DrawerContent drawerOpen={drawerOpen} />
          <Divider sx={{ mt: 3, mb: 3, mr: 2, ml: 2 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Divider sx={{ mt: 3, mb: 3, mr: 2, ml: 2 }} />
          
          <Box sx={{ ml: 1.5, mb: 3 }}>
            <UserMenu drawerOpen={drawerOpen} />
          </Box>
          {/* <ThemeSwitcher sx={{ pb: 3 }} /> */}
        </Drawer>
        {isMobile && <HeaderAppBarMobile />}
        <Box
          component="main"
          sx={{
            
            flexGrow: 1,
            p: 0,
            ml: { sm: `${isMobile ? 0 : drawerOpen ? drawerWidth : miniDrawerWidth}px` },
            height: '100%',
            overflow: 'auto',
            fontSize: '0.875rem',
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                height: 53,
                width: '100%',
                backgroundColor: theme.palette.primary.drawerBackground,
              }}
            />
          ) : (
            <>
            {/* <MainBreadcrumbs />
            <Divider /> */}
            </>
          )}
          
          <Box
            sx={{
              
              flexGrow: 1,
              pr: { xs: 1, sm: 2 },
              pl: { xs: 1, sm: 2  },
              m: 0,
              pb: { xs: 15, s: 0 }
            }}
          >
            {children}
          </Box>
        </Box>
        <RightDrawer open={isOpen} onClose={closeRightDrawer}>
          {content}
        </RightDrawer>
      </Box>
      {isMobile && <BottomNav />}
    </Box>
  );
};

export default MainLayout;