// utils.js
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

export const getStatusChipColor = (status) => {
  switch (status) {
    case 'Applied':
      return 'primary';
    case 'Rejected':
      return 'error';
    case 'Withdrawn':
      return 'warning';
    case 'Accepted':
      return 'success';
    default:
      return 'default';
  }
};

export const getOutlookIcon = (outlook, theme) => {
  switch (outlook) {
    case 1:
      return <ThumbUpIcon sx={{ color: theme.palette.secondary.main }} />;
    case -1:
      return <ThumbDownIcon sx={{ color: theme.palette.secondary.main }} />;
    default:
      return <ThumbsUpDownIcon sx={{ color: 'lightgray' }} />;
  }
};

export const truncateText = (text, length) => {
  if (text.length <= length) return text;
  return `${text.substring(0, length)}...`;
};

export const formatTextToHtml = (text) => {
  if (!text) return text;
  const paragraphs = text.split('\n\n').map(paragraph => {
    const lines = paragraph.split('\n').join('<br>');
    return `<p>${lines}</p>`;
  });
  return paragraphs.join('');
};