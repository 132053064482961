import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Divider, Typography, Box, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ImageDisplay from './ResumeImageDisplay'; // Import the ImageDisplay component

const ResumeCard = ({ file, getPNGUrl, checkImageExists, onMenuClick, onDeleteClick, anchorEl, selectedFile, handleMenuClose }) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const handleDetailsClick = () => {
    setDetailsDialogOpen(true);
    handleMenuClose();
  };

  const handleDetailsClose = () => {
    setDetailsDialogOpen(false);
  };

  const { interviewRoles = [], softSkills = [], hardSkills = [], skills = [], workHistory = [], highlights = [] } = file;

  return (
    <>
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardContent sx={{ flex: 1 }}>
          <ImageDisplay fileKey={file.s3Key} getPNGUrl={getPNGUrl} checkImageExists={checkImageExists} resumeStatus={file.resumeStatus} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>{file.title}</Typography>
              <Typography variant="caption" color="textSecondary">{format(new Date(file.updatedAt), 'MMMM dd, yyyy')}</Typography>
            </Box>
            <IconButton onClick={(event) => onMenuClick(event, file)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedFile === file}
              onClose={handleMenuClose}
            >
              <MenuItem component="a" href={`https://joboomi-resumes-bucket-dev.s3.us-east-1.amazonaws.com/${file.s3Key}`} target="_blank">
                <OpenInNewIcon sx={{ mr: 1 }} />
                View Resume
              </MenuItem>
              <MenuItem onClick={handleDetailsClick}>
                <ManageSearchIcon sx={{ mr: 1 }} />
                Details
              </MenuItem>
              <Divider />
              <MenuItem onClick={onDeleteClick} sx={{ color: 'red' }}>
                <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                Delete Resume
              </MenuItem>
            </Menu>
          </Box>
          <Typography variant="body2" gutterBottom>{file.description}</Typography>
        </CardContent>
      </Card>

      <Dialog open={detailsDialogOpen} onClose={handleDetailsClose} sx={{ overflow: 'scroll'}}>
        <DialogTitle>Resume Details</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>
            <strong>Summary:</strong> {highlights?.summary}
          </DialogContentText>
          <DialogContentText sx={{mb: 2}}>
            <strong>Hard Skills (from your resume):</strong> {skills?.explicitSkills?.hardSkills?.join(', ')}
          </DialogContentText>
          <DialogContentText sx={{mb: 2}}>
            <strong>Soft Skills (from your resume):</strong> {skills?.explicitSkills?.softSkills?.join(', ')}
          </DialogContentText>
          <DialogContentText sx={{mb: 2}}>
            <strong>Implied Skills:</strong>  {skills?.implicitSkills?.join(', ')}
          </DialogContentText>
            <strong>Work History:</strong>
            {workHistory?.map((job, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1"><strong>{job.title}</strong><br />{job.company}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Duration:</strong> {job.duration}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Overview:</strong> {job.overview}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Responsibilities:</strong>
                  </Typography>
                  <ul>
                    {job.responsibilities?.map((responsibility, rIndex) => (
                      <li key={rIndex}>
                        <Typography variant="body1">{responsibility}</Typography>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ))}
          <Box sx={{ mb: 2, mt: 2 }}>
            <strong>Highlights:</strong>
            <ul>
              {highlights?.highlights?.map((highlight, index) => (
                <li key={index} variant="body1">{highlight}</li>
              ))}
            </ul>
          </Box>
          <Box variant="body2" sx={{mb: 2}}>
          <strong>Achievements:</strong>
            <ul>
              {highlights?.achievements?.map((achievement, index) => (
                <li key={index} variant="body1">{achievement}</li>
              ))}
            </ul>
          </Box>
          <DialogContentText sx={{mb: 2}}>
            <strong>Education:</strong> {highlights?.education}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailsClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResumeCard;