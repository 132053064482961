import React, { useState, useEffect, useCallback } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useLazyQuery, gql } from '@apollo/client';
import { List, ListItem, ListItemAvatar, ListItemText, Typography, Divider, Paper, IconButton, Popover } from '@mui/material';
import debounce from 'lodash.debounce';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: theme.spacing(2),
  marginTop: 0,
  marginRight: theme.spacing(2),
  marginBlock: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  color: '#f5f5f5',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#f5f5f5',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SEARCH_CONTACTS = gql`
  query SearchContacts($searchTerm: String!) {
    searchContacts(searchTerm: $searchTerm) {
      id
      firstName
      lastName
    }
  }
`;

const SearchBar = ({ sx, miniDrawer }) => {
  const [inputValue, setInputValue] = useState('');
  const [contactResults, setContactResults] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchContacts, { loading, error, data }] = useLazyQuery(SEARCH_CONTACTS, {
    onCompleted: (data) => {
      console.log("Contact search completed:", data);
      setContactResults(data.searchContacts);
    },
  });

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm) {
        searchContacts({ variables: { searchTerm } });
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    } else {
      setContactResults([]);
    }
  }, [inputValue, debouncedSearch]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleBlur = () => {
    setInputValue('');
    setContactResults([]);
  };

  const handleResultClick = (id) => {
    window.location.href = `/contacts`;
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
    setInputValue('');
    setContactResults([]);
  };

  return (
    <>
      {miniDrawer ? (
        <IconButton onClick={handleIconClick}>
          <SearchIcon style={{ color: '#f5f5f5' }} />
        </IconButton>
      ) : (
        <Search sx={sx}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Autocomplete
            freeSolo
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={contactResults}
            getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
            open={inputValue.length > 0 && contactResults.length > 0}
            renderInput={(params) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }} ref={params.InputProps.ref}>
                <StyledInputBase
                  {...params.inputProps}
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search', ...params.inputProps }}
                  onBlur={handleBlur}
                />
              </Box>
            )}
            renderOption={(props, option) => (
              <>
                <Typography variant="subtitle1" sx={{ padding: 1, fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                  Contacts
                </Typography>
                <Divider />
                <List>
                  {contactResults.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No results found" />
                    </ListItem>
                  )}
                  {contactResults.map((contact, index) => (
                    <React.Fragment key={contact.id}>
                      <ListItem button onClick={() => handleResultClick(contact.id)}>
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText primary={`${contact.firstName} ${contact.lastName}`} />
                      </ListItem>
                      {index < contactResults.length - 1 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                  ))}
                </List>
              </>
            )}
          />
        </Search>
      )}
      <Popover
        open={expanded}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { width: '80%', maxWidth: 500, p: 2, backgroundColor: '#f5f5f5' },
        }}
      >
        <Autocomplete
          freeSolo
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={contactResults}
          getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
          open={inputValue.length > 0 && contactResults.length > 0}
          renderInput={(params) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} ref={params.InputProps.ref}>
              <StyledInputBase
                {...params.inputProps}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search', ...params.inputProps }}
                onBlur={handleBlur}
              />
            </Box>
          )}
          renderOption={(props, option) => (
            <>
              <Typography variant="subtitle1" sx={{ padding: 1, fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                Contacts
              </Typography>
              <Divider />
              <List>
                {contactResults.length === 0 && (
                  <ListItem>
                    <ListItemText primary="No results found" />
                  </ListItem>
                )}
                {contactResults.map((contact, index) => (
                  <React.Fragment key={contact.id}>
                    <ListItem button onClick={() => handleResultClick(contact.id)}>
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText primary={`${contact.firstName} ${contact.lastName}`} />
                    </ListItem>
                    {index < contactResults.length - 1 && <Divider variant="inset" component="li" />}
                  </React.Fragment>
                ))}
              </List>
            </>
          )}
        />
      </Popover>
    </>
  );
};

export default SearchBar;