import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';
import ProfileEditForm from '../components/ProfileEditForm'; // Separate form fields into ProfileEditForm
import { gql, useMutation, useQuery } from '@apollo/client';

const GET_USER = gql`
  query GetUser {
    getUser {
      firstName
      lastName
      phoneNumber
      dateOfBirth
      gender
      address {
        street
        city
        state
        zip
        country
      }
      preferredLanguage
      timeZone
      profilePictureUrl
      subscriptionPlan
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      firstName
      lastName
      phoneNumber
      dateOfBirth
      gender
      address {
        street
        city
        state
        zip
        country
      }
      preferredLanguage
      timeZone
      profilePictureUrl
      subscriptionPlan
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      firstName
      lastName
      phoneNumber
      dateOfBirth
      gender
      address {
        street
        city
        state
        zip
        country
      }
      preferredLanguage
      timeZone
      profilePictureUrl
      subscriptionPlan
    }
  }
`;

const ProfileEdit = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_USER);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [profileExists, setProfileExists] = useState(false);

  useEffect(() => {
    if (data) {
      setProfileExists(!!data.getUser);
    }
  }, [data]);

  const handleSave = async (updatedUser) => {
    try {
      if (profileExists) {
        await updateUser({ variables: { input: updatedUser } });
      } else {
        await createUser({ variables: { input: updatedUser } });
      }
      navigate('/profile-edit');
    } catch (err) {
      console.error('Error saving user:', err);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 3 }}>
      <Typography component="h1" variant="h5">
        {profileExists ? 'Edit Profile' : 'Create Profile'}
      </Typography>
      <ProfileEditForm user={data?.getUser} onSave={handleSave} />
    </Paper>
  );
};

export default ProfileEdit;