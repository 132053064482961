import React, { useState, useContext } from 'react';
import { Stepper, Step, StepLabel, Box, Button, Divider, Chip, Typography, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, useMediaQuery, Link } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import CompareIcon from '@mui/icons-material/Compare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useTheme } from '@mui/material/styles';
import SnackbarComponent from '../SnackbarComponent';
import { useRightDrawer } from '../../RightDrawerContext'; // Import the context
import CompanyAvatar from '../CompanyAvatar';
import ResumeAssist from '../ResumeAssist/ResumeAssist'; // Import the ResumeAssist component
import CoverLetterAssist from '../CoverLetterAssist/CoverLetterAssist'; // Import the ResumeAssist component
import InterviewPrepAssist from '../InterviewPrepAssist/InterviewPrepAssist'; // Import the ResumeAssist component
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DiamondIcon from '@mui/icons-material/Diamond';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ArchiveIcon from '@mui/icons-material/Archive';
import WhatsHotIcon from '@mui/icons-material/Whatshot';
import { getStatusChipColor, formatTextToHtml } from '../utilities/jobOpportunititesUtilities';
import { STATUS_OPTIONS } from '../../config';
import { useSnackbar } from '../../SnackbarContext';
import { AppContext } from '../../AppContext'; // Update this line
import { UPDATE_JOB_OPPORTUNITY_GROUP } from '../queries';
import { useMutation, gql } from '@apollo/client';

const JobDetails = ({ formData, handleEditClick, deleteJobOpportunity, isExpanded, refetch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isResumeAssistOpen, setIsResumeAssistOpen] = useState(false); // State for ResumeAssist modal
  const [isCoverLetterAssistOpen, setIsCoverLetterAssistOpen] = useState(false); // State for ResumeAssist modal
  const [isInterviewPrepAssistOpen, setIsInterviewPrepAssistOpen] = useState(false); // State for ResumeAssist modal
  const { closeRightDrawer, rightDrawerData } = useRightDrawer(); // Use the context and get rightDrawerData
  const { globalOnSave } = useContext(AppContext);
  const { showSnackbar } = useSnackbar();

  const [updateJobOpportunityGroup] = useMutation(UPDATE_JOB_OPPORTUNITY_GROUP, {
    onCompleted: () => {
      globalOnSave();
    },
    onError: (error) => {
      console.error('Error updating job group:', error);
    },
  });
  
  const handleGroupChange = (newGroup) => (event) => {
    event.stopPropagation();
  
    updateJobOpportunityGroup({
      variables: { id: formData.id, group: newGroup },
    }).then(() => {
      showSnackbar({ message: `Job moved to ${newGroup}`, severity: 'success' });
    }).catch((error) => {
      showSnackbar({message: `Failed to move job to ${newGroup}`, severity: 'error' });
    });
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteJobOpportunity({ variables: { id: formData.id } });
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleResumeAssistOpen = () => {
    setIsResumeAssistOpen(true);
    handleMenuClose();
  };

  const handleCoverLetterAssistOpen = () => {
    setIsCoverLetterAssistOpen(true);
    handleMenuClose();
  };

  const handleInterviewPrepAssistOpen = () => {
    setIsInterviewPrepAssistOpen(true);
    handleMenuClose();
  };

  const handleResumeAssistClose = () => {
    setIsResumeAssistOpen(false);
  };
  
  const handleCoverLetterAssistClose = () => {
    setIsCoverLetterAssistOpen(false);
  };

  const handleInterviewPrepAssistClose = () => {
    setIsInterviewPrepAssistOpen(false);
  };

  const getActiveStep = (status) => {
    const index = STATUS_OPTIONS.indexOf(status);
    if (index === -1) {
      // Handle case where status is not found in STATUS_OPTIONS
      return 0;
    }
    if (['Rejected', 'Accepted', 'Withdrawn'].includes(status)) {
      return 5;
    }
    return index;
  };

  const activeStep = getActiveStep(formData.processStatus);

  const getLabel = (step) => {
    if (step === 5) {
      if (formData.processStatus === 'Rejected' || formData.processStatus === 'Withdrawn') {
        return formData.processStatus;
      }
      return 'Accepted';
    }
    return STATUS_OPTIONS[step];
  };

  const renderField = (label, value) => (
    <Grid item xs={6}>
      <Typography variant="body2" color="textSecondary">{label}:</Typography>
      <Typography variant="body1" sx={{ color: value ? 'inherit' : 'lightgray', overflow: label === 'Website' ? 'hidden' : 'inherit', textOverflow: label === 'Website' ? 'ellipsis' : 'inherit', whiteSpace: label === 'Website' ? 'nowrap' : 'inherit' }}>
        {label === 'Website' && value ? <Link href={value} target="_blank" rel="noopener noreferrer" underline="hover" color="primary">{value}</Link> : (value || 'Not Found')}
      </Typography>
    </Grid>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        {!isExpanded ? (  
          <Chip
            label={formData.processStatus}
            color={getStatusChipColor(formData.processStatus)}
            // onClick={handleStatusMenuOpen}
            sx={{ width: '140px', mb: 2 }}
          />
        ) : (
          <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', mb: 3, mt: 2 }}>
            {STATUS_OPTIONS.slice(0, 4).map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
            <Step key={5}>
              <StepLabel>{getLabel(5)}</StepLabel>
            </Step>
          </Stepper>
          )}
        
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <Typography variant="h6" gutterBottom>{formData.positionTitle}</Typography>
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
>
  <MenuItem onClick={handleEditClick}>
    <EditIcon sx={{ mr: 1 }} />
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Typography>Edit Opportunity</Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  </MenuItem>
  <MenuItem component="a" href={formData.postingUrl} target="_blank" rel="noopener noreferrer">
    <OpenInNewIcon sx={{ mr: 1 }} />
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Typography>View Original Posting</Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  </MenuItem>
  {formData.group == 'active' && (
    <>
      <Divider />
      <MenuItem onClick={handleResumeAssistOpen}>
        <CompareIcon sx={{ mr: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography>Resume Feedback</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <DiamondIcon sx={{ ml: 2, color: '#2572fd', fontSize: 'medium' }} />
        </Box>
      </MenuItem>
      <MenuItem onClick={handleCoverLetterAssistOpen}>
        <TextSnippetIcon sx={{ mr: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography>Cover Letter Assist</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <DiamondIcon sx={{ ml: 2, color: '#2572fd', fontSize: 'medium' }} />
        </Box>
      </MenuItem>
      <MenuItem onClick={handleInterviewPrepAssistOpen}>
        <SpeakerNotesIcon sx={{ mr: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography>Interview Prep</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <DiamondIcon sx={{ ml: 2, color: '#2572fd', fontSize: 'medium' }} />
        </Box>
      </MenuItem>
    </>
  )}
  <Divider />
  {(formData.group == 'toReview' || formData.group=='bookmarked' || formData.group=='archived') && (
    <MenuItem onClick={handleGroupChange('active')}>
      <WhatsHotIcon sx={{ mr: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography>Move to Active</Typography>
        
      </Box>
    </MenuItem>
  )}
  {(formData.group == 'toReview' || formData.group=='archived' || formData.group=='active') && (
      <MenuItem onClick={handleGroupChange('bookmarked')}>
        <BookmarkIcon sx={{ mr: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography>Move to Bookmarked</Typography>
          
        </Box>
      </MenuItem>
  )}
  {(formData.group == 'toReview' || formData.group=='bookmarked' || formData.group=='active') && (
      <MenuItem onClick={handleGroupChange('archived')}>
        <ArchiveIcon sx={{ mr: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography>Archive Job</Typography>
          
        </Box>
      </MenuItem>
  )}
  <Divider />
  <MenuItem onClick={handleDeleteClick} sx={{ color: 'red' }}>
    <DeleteIcon sx={{ mr: 1, color: 'red' }} />
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Typography>Delete Opportunity</Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  </MenuItem>
</Menu>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 1 }}>
        <CompanyAvatar website={formData.website} />
        <Typography variant="h5" gutterBottom sx={{ mt: 1, ml: 1 }}>{formData.company}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Job Listing:</Typography>
          <Typography variant="body1" sx={{ overflow: 'hidden', color: formData.postingUrl ? 'inherit' : 'lightgray' }}>
            {formData.postingUrl ? <Link href={formData.postingUrl} target="_blank" rel="noopener noreferrer" underline="hover" color="primary">{formData.postingUrl}</Link> : 'Not Found'} 
          </Typography>
        </Grid>
        {renderField('Status', formData.processStatus)}
        {renderField('Group', formData.group ? formData.group.charAt(0).toUpperCase() + formData.group.slice(1) : '')}
        {renderField('Date Applied', formData.dateApplied)}
        {renderField('Salary', formData.salary)}
        {renderField('Location', formData.companyLocation)}
        {renderField('Working Location', formData.workingLocation)}
        {renderField('Employment Type', formData.employmentType)}
        {renderField('Website', formData.website)}
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Company Overview:</Typography>
          <Typography variant="body1" sx={{ color: formData.companyOverview ? 'inherit' : 'lightgray' }}>
            {formData.companyOverview || 'Not Found'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Position Overview:</Typography>
          <Typography variant="body1" sx={{ color: formData.positionOverview ? 'inherit' : 'lightgray' }}>
            {formData.positionOverview || 'Not Found'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Responsibilities:</Typography>
          <Typography variant="body1" sx={{ color: formData.responsibilities ? 'inherit' : 'lightgray' }}>
          <span dangerouslySetInnerHTML={{ __html: formatTextToHtml(formData.responsibilities) || 'Not Found' }} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
        
          <Typography variant="body2" color="textSecondary">Qualifications:</Typography>
          <Typography variant="body1" sx={{ color: formData.qualifications ? 'inherit' : 'lightgray' }}>
            <span dangerouslySetInnerHTML={{ __html: formatTextToHtml(formData.qualifications) || 'Not Found' }} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Travel Requirements:</Typography>
          <Typography variant="body1" sx={{ color: formData.travelRequirements ? 'inherit' : 'lightgray' }}>
            {formData.travelRequirements || 'Not Found'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Benefits:</Typography>
          <Typography variant="body1" sx={{ color: formData.benefits ? 'inherit' : 'lightgray' }}>
          <span dangerouslySetInnerHTML={{ __html: formatTextToHtml(formData.benefits) || 'Not Found' }} />
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job opportunity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message="Job deleted successfully!"
      />
      <ResumeAssist
        open={isResumeAssistOpen}
        handleClose={handleResumeAssistClose}
        jobData={formData}
      />
      <CoverLetterAssist
        open={isCoverLetterAssistOpen}
        handleClose={handleCoverLetterAssistClose}
        jobData={formData}
      />
      <InterviewPrepAssist
        open={isInterviewPrepAssistOpen}
        handleClose={handleInterviewPrepAssistClose}
        jobData={formData}
      />
    </Box>
  );
};

export default JobDetails;