import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.info.light,
  marginLeft: theme.spacing(2),
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.info.main,
  },
}));

const SnackbarComponent = ({ open, message, link, severity = 'success', handleClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <StyledAlert
        onClose={handleClose}
        severity={severity}
        action={link && <StyledLink to={link}>Learn more</StyledLink>}
      >
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;