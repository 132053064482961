import React from 'react';
import JoboomiLogo from './assets/Joboomi512.png';

const logoStyle = {
  position: 'absolute',
  top: '-32px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 65,
  height: 65,
  zIndex: 9999999,
};

const ModalHeader = () => (
  <img src={JoboomiLogo} alt="Joboomi Logo" style={logoStyle} />
);

export default ModalHeader;