// purpleTheme.js
import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';

const purpleTheme = createTheme({
  ...baseTheme,
  themeName: 'purple',
  palette: {
    mode: 'light',
    ...baseTheme.palette,
    
    primary: {
      main: '#5f1fb1', // specific primary color for purple themea
    },
    secondary: {
      main: '#2572fd',
    },
    background: {
      default: '#f5f5f5',
    },
    // other palette settings specific to purple theme
  },
  components: {
    ...baseTheme.components,
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...baseTheme.components.MuiDrawer.styleOverrides.root,
          '&.leftDrawer': {
            '& .MuiPaper-root': {
              boxShadow: '2px 0 5px -0px #666',
              backgroundColor: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...baseTheme.components.MuiListItem.styleOverrides.root,
          '&.leftDrawerListItem': {
            ...baseTheme.components.MuiListItem.styleOverrides.root['&.leftDrawerListItem'],
            // specific left drawer item styles for purple theme
            backgroundColor: 'transparent',
            color: '#fff',
            marginLeft: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius,
            marginBottom: 10,
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&.rightDrawerItem': {
            ...baseTheme.components.MuiListItem.styleOverrides.root['&.rightDrawerItem'],
            // specific right drawer item styles for purple theme
            backgroundColor: '#f3e5f5',
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          ...baseTheme.components.MuiListItemIcon.styleOverrides.root,
          '&.leftDrawerListItemIcon': {
            ...baseTheme.components.MuiListItemIcon.styleOverrides.root['&.leftDrawerListItemIcon'],
            color: '#fff',
          },
        },
      },
    },
    // other component overrides specific to purple theme
  },
});

export default purpleTheme;