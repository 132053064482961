import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, CardMedia, CardActions, Button, Grid } from '@mui/material';

const Resources = () => {
  const [resources, setResources] = useState([]);

  const fetchResources = async () => {
    const response = await fetch('/api/resources');
    const data = await response.json();
    setResources(data.resources);
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }} >
        {resources.map((resource, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={resource.image}
                alt={resource.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {resource.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {resource.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Resources;
