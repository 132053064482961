import React, { useState } from 'react';
import { Accordion, Chip, Divider, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PsychologyAltSharpIcon from '@mui/icons-material/PsychologyAltSharp';
import styled from 'styled-components';

const AccordionDetailsStyled = styled(AccordionDetails)`
  max-height: 200px; // Set a maximum height for accordion details
  overflow-y: auto; // Add vertical scroll if content overflows
`;

const FeedbackSections = ({ feedback }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    feedback && (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h6">Feedback</Typography>
        </Divider>
        <Accordion expanded={expanded === 'talkingPoints'} onChange={handleChange('talkingPoints')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <TipsAndUpdatesIcon sx={{ mr: 1, color: '#62a5fb' }} /> Talking Points
            </Typography>
          </AccordionSummary>
          <AccordionDetailsStyled>
            <Typography variant="body2">
              {feedback.talkingPoints.replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br />')}
            </Typography>
          </AccordionDetailsStyled>
        </Accordion>
        <Accordion expanded={expanded === 'questions'} onChange={handleChange('questions')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <PsychologyAltSharpIcon sx={{ mr: 1, color: '#62a5fb' }} /> Questions
            </Typography>
          </AccordionSummary>
          <AccordionDetailsStyled>
            <Typography variant="body2">Below are some potential questions you may be asked during an interview with {feedback.interviewer}:</Typography>
            <List dense>
  {feedback.interviewQuestions.map((item, index) => (
    <ListItem key={index} sx={{ py: 0.1, display: 'flex', alignItems: 'flex-start' }}>
      <QuestionAnswerIcon sx={{ fontSize: 'sm', color: '#2572fd' }} />
      <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary={item} sx={{ ml: 1 }} />
    </ListItem>
  ))}
</List>
          </AccordionDetailsStyled>
        </Accordion>
        
      </>
    )
  );
};

export default FeedbackSections;