import React from 'react';
import { ListItem, ListItemIcon, Badge, Box, Typography, Tooltip, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useCustomTheme } from '../ui/CustomThemeProvider';

const DrawerListItem = ({ icon, text, to, badgeContent, dotBadge, onClick, miniDrawer }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const { theme } = useCustomTheme();

  return (
    <Tooltip
      title={text}
      placement="right"
      arrow
      disableHoverListener={!miniDrawer} // Disable tooltip when not in mini drawer mode
    >
      <ListItem
        button
        component={Link}
        to={to}
        onClick={onClick}
        selected={isActive}    
        className="leftDrawerListItem"
        sx={{ }}    
      >
        <ListItemIcon
          className="leftDrawerListItemIcon"
        >
          {dotBadge && !isActive ? (
            <Badge variant="dot" color="error">
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </ListItemIcon>
        {!miniDrawer && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ fontSize: '0.85rem', verticalAlign: 'middle', ml: '10px' }}>
              {text}
            </Typography>
            {badgeContent !== undefined && (
              <Badge badgeContent={badgeContent} color="primary" sx={{ mr: '10px' }} />
            )}
          </Box>
        )}
      </ListItem>
    </Tooltip>
  );
};

export default DrawerListItem;