// baseTheme.js
import { Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#444',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    // other common typography settings
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // common button styles
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          '&.css-1s1yeag': {
            paddingTop: '0px !important',
          },
          '[role="tabpanel"] > &.MuiBox-root': {
            paddingTop: '0px !important',
            // other styles
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '1px 1px 5px 0px #666', // Dark background for AppBar
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '&.leftDrawer': {
            '& .MuiPaper-root': {
              boxSizing: 'border-box',
              opacity: 1,
              overflowX: 'hidden',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          '&.leftDrawerListItemIcon': {
            minWidth: 0,
            justifyContent: 'flex-start',
          },
        },
      },
    },
    
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // common list item styles
          '&.leftDrawerListItem': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
            border: 'none',
            mb: 2,
            '&.Mui-selected': {
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                
              },
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&.rightDrawerItem': {
            // common right drawer item styles
          },
        }),
      },
    },
    // other common component overrides
  },
});

export default baseTheme;