import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const SnackbarContext = createContext();

const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.info.light,
  marginLeft: theme.spacing(2),
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.info.main,
  },
}));

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success',
    link: '',
  });

  const showSnackbar = ({ message, severity = 'success', link = '' }) => {
    setSnackbarState({
      open: true,
      message,
      severity,
      link,
    });
  };

  const handleClose = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleClose}>
        <StyledAlert
          onClose={handleClose}
          severity={snackbarState.severity}
          action={snackbarState.link && <StyledLink to={snackbarState.link}>Learn more</StyledLink>}
        >
          {snackbarState.message}
        </StyledAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);