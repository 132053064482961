import React, { useState } from 'react';
import { Drawer, Box, Typography, Divider, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import JobOpportunityContent from './drawercontent/JobOpportunityContent';
import ContactContent from './drawercontent/ContactContent';
import { useRightDrawer } from '../RightDrawerContext';
import { useLocation } from 'react-router-dom';

const rightDrawerWidth = 360;

const contentComponents = {
  jobOpportunity: JobOpportunityContent,
  addOpportunity: JobOpportunityContent,
  contact: ContactContent,
  addContact: ContactContent,
  // Add other content types here, e.g., 'contact': ContactContent,
};

const RightDrawer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { rightDrawerOpen, rightDrawerContentType, rightDrawerContent, rightDrawerRefetch, closeRightDrawer } = useRightDrawer();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const ContentComponent = contentComponents[rightDrawerContentType] || null;

  const titles = {
    addOpportunity: 'Add Job Opportunity',
    jobOpportunity: 'Opportunity',
    addContact: 'Add Contact',
    contact: 'Contact',
  };
  
  const title = titles[rightDrawerContentType] || '';

  const handleClose = () => {
    if (rightDrawerRefetch && location.pathname === '/job-opportunities') {
      rightDrawerRefetch();
    }
    if (rightDrawerRefetch && location.pathname === '/contacts') {
      rightDrawerRefetch();
    }
    closeRightDrawer();
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Drawer
      anchor="right"
      open={rightDrawerOpen}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          zIndex: theme.zIndex.drawer + 2,
          boxSizing: 'border-box',
          width: isMobile ? '90%' : isExpanded ? '80%' : rightDrawerWidth,
          height: '100%',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: '#5f1fb1' }}>
        {!isMobile && (
          <IconButton onClick={handleExpand} sx={{ backgroundColor: '#a092ed', mr: 1 }}>
            <AspectRatioIcon sx={{ color: '#fff' }} />
          </IconButton>
        )}
        <Typography variant="h5" sx={{ color: '#fff', flexGrow: 1 }}>{title}</Typography>
        <IconButton onClick={handleClose} sx={{ backgroundColor: '#a092ed', ml: 1 }}>
          <ArrowForwardIosIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ padding: 1 }}>
        {ContentComponent ? <ContentComponent {...rightDrawerContent} refetch={rightDrawerRefetch} closeDrawer={closeRightDrawer} isExpanded={isExpanded} isAdding={rightDrawerContentType === 'addOpportunity'} /> : null}
      </Box>
    </Drawer>
  );
};

export default RightDrawer;