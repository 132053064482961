import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  TablePagination,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Link,
  Alert,
  Divider
} from '@mui/material';
import { format } from 'date-fns';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EmailIcon from '@mui/icons-material/Email';
import Confetti from 'react-confetti';
import { useMutation } from '@apollo/client';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useRightDrawer } from '../RightDrawerContext';
import {
  UPDATE_FAVORITE_STATUS,
  UPDATE_OUTLOOK_STATUS,
  UPDATE_PROCESS_STATUS
} from './queries';
import CompanyAvatar from './CompanyAvatar';
import JobNoteModal from './JobNoteModal';
import ReviewEmailsDialog from './ReviewEmailsDialog'; // Import the new component
import {
  getStatusChipColor,
  getOutlookIcon,
  truncateText
} from './utilities/jobOpportunititesUtilities';
import { STATUS_OPTIONS } from '../config';

const JobOpportunitiesTable = ({
  jobs,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  emptyRows,
  label,
  refetch
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuJobId, setMenuJobId] = useState(null);
  const [outlookMenuAnchorEl, setOutlookMenuAnchorEl] = useState(null);
  const [selectedOutlookJob, setSelectedOutlookJob] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [selectedNoteJob, setSelectedNoteJob] = useState(null);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null); // State for status menu
  const [isReviewEmailsDialogOpen, setIsReviewEmailsDialogOpen] = useState(false); // State for review emails dialog
  const [selectedReviewEmails, setSelectedReviewEmails] = useState([]); // State for selected review emails
  const [selectedJob, setSelectedJob] = useState(null); // State for selected review emails
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiMessage, setConfettiMessage] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { openRightDrawer } = useRightDrawer();

  const [updateFavoriteStatus] = useMutation(UPDATE_FAVORITE_STATUS, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.error('Error updating favorite status:', error);
    },
  });

  const [updateOutlookStatus] = useMutation(UPDATE_OUTLOOK_STATUS, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.error('Error updating outlook status:', error);
    },
  });

  const [updateProcessStatus] = useMutation(UPDATE_PROCESS_STATUS, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.error('Error updating process status:', error);
    },
  });

  const handleRowClick = (job) => {
    openRightDrawer('jobOpportunity', job, refetch);
  };

  const handleFavoriteClick = (jobId, currentFavoriteStatus) => {
    updateFavoriteStatus({
      variables: { id: jobId, favorite: !currentFavoriteStatus }
    });
  };

  const handleOutlookClick = (event, job) => {
    event.stopPropagation();
    setSelectedOutlookJob(job);
    setOutlookMenuAnchorEl(event.currentTarget);
  };

  const handleOutlookMenuClose = (event) => {
    if (event) event.stopPropagation();
    setOutlookMenuAnchorEl(null);
    setSelectedOutlookJob(null);
  };

  const handleOutlookChange = (event, jobId, outlookValue) => {
    event.stopPropagation();
    updateOutlookStatus({
      variables: { id: jobId, outlook: outlookValue }
    });
    handleOutlookMenuClose({ stopPropagation: () => {} });
  };

  const handleNoteClick = (event, job) => {
    event.stopPropagation();
    setSelectedNoteJob(job);
    setIsNoteModalOpen(true);
  };

  const handleNoteModalClose = () => {
    setIsNoteModalOpen(false);
    setSelectedNoteJob(null);
  };

  const handleStatusMenuOpen = (event, jobId) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
    setMenuJobId(jobId);
  };

  const handleStatusMenuClose = (event) => {
    if (event) event.stopPropagation();
    setStatusMenuAnchorEl(null);
  };

  const handleStatusChange = (event, jobId, status) => {
    event.stopPropagation();
    updateProcessStatus({
      variables: { id: jobId, processStatus: status }
    }).then(() => {
      if (status === 'Accepted') {
        setShowConfetti(true);
        setConfettiMessage('Wohoo! Best of luck in your new chapter!');
        // enqueueSnackbar('Congratulations! Job status changed to Accepted.', { variant: 'success' });
        setTimeout(() => {
          setShowConfetti(false);
          setConfettiMessage('');
        }, 8000); // Confetti lasts for 5 seconds
      } else {
        // enqueueSnackbar(`Job status changed to ${status}`, { variant: 'success' });
      }
      handleStatusMenuClose({ stopPropagation: () => {} });
    }).catch((error) => {
      // enqueueSnackbar(`Failed to change job status to ${status}`, { variant: 'error' });
    });
  };

  const handleEmailIconClick = (event, job) => {
    event.stopPropagation();
    if (job.reviewEmails?.length > 0) {
      setSelectedReviewEmails(job.reviewEmails);
      setSelectedJob(job);
      setIsReviewEmailsDialogOpen(true);
    }
  };

  const handleReviewEmailsDialogClose = () => {
    setIsReviewEmailsDialogOpen(false);
    setSelectedReviewEmails([]);
    setSelectedJob(null);
  };

  return (
    <>
    {showConfetti && 
      <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
      style={{ position: 'fixed', top: 0, left: 0, zIndex: 1300 }}
      />
    }
    {showConfetti && (
        <Typography
          variant="h4"
          sx={{
            position: 'fixed',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
            zIndex: 1300
          }}
        >
          {confettiMessage}
        </Typography>
      )}
    {label == 'toreview' && (
        <>
          <Alert severity="info"  component="div" sx={{ mb: 2 }}>
            We had trouble extracting data from the opportunities listed below. Please review and update the information, if necessary. You can move them to your Active or Bookmarked folders when ready. <b>Possible causes:</b> The job posting may have been removed, the website may have changed, or the job posting is behind a paywall/login wall. You can use the Chrome extension in your browser to pull the information, or manually updated it. 
        </Alert>
        </>
      )}
    <Paper sx={{ width: '100%', overflow: 'hidden', pt: 0 }}>
      
      {isSmallScreen && (
        <>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={jobs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ pt: 0, overflow: 'hidden' }}
          />
          <Divider />
        </>
      )}
      <TableContainer sx={{ pt: 0 }}>
        <Table sx={{ pt: 0 }}>
          {!isSmallScreen && label != 'toreview' && (
            <TableHead sx={{ pt: 0 }}>
              <TableRow>
                <TableCell align="center" sx={{ width: '120px' }}></TableCell>
                <TableCell sx={{ flexGrow: 1 }}>Position</TableCell>
                {!isSmallScreen && <TableCell sx={{ width: '150px' }}>Added On</TableCell>}
              </TableRow>
            </TableHead>
          )}
                    <TableBody>
            {(rowsPerPage > 0
              ? jobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : jobs
            ).map((job) => (
              <TableRow key={job.id} hover onClick={(event) => handleRowClick(job)}>
                <TableCell align="center" sx={{ verticalAlign: 'top', pt: 3, pr: 0, pb: 3, width: '120px' }}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    {label != 'toreview' ? (
                    <>
                    <Tooltip title="Favorite" placement="top">
                      <IconButton
                        size="small"
                        sx={{ ml: 0, bgcolor: job.favorite ? 'transparent' : 'transparent' }}
                        style={{
                          color: '#fff',
                          position: 'absolute',
                          left: -35,
                          top: '5%',
                          transform: 'translateY(-50%)',
                          padding: '2px',
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                        }}
                        onClick={(event) => { event.stopPropagation(); handleFavoriteClick(job.id, job.favorite); }}
                      >
                        {job.favorite ? (
                          <StarIcon sx={{ color: '#ebbb35' }} />
                        ) : (
                          <StarBorderIcon sx={{ color: 'lightgray' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                    <CompanyAvatar website={job.website} smallscreen={isSmallScreen} />
                    </>
                    ) : (
                      <CompanyAvatar smallscreen={isSmallScreen} />
                    )}
                  </div>
                  <div style={{ position: 'relative', display: 'inline-block', marginTop: 7 }}>
                    <Chip
                      label={job.processStatus}
                      color={getStatusChipColor(job.processStatus)}
                      onClick={(event) => handleStatusMenuOpen(event, job.id)}
                      style={{ width: '90px', mt: 2 }}
                    />
                  </div>
                  <Menu
                    anchorEl={statusMenuAnchorEl}
                    open={Boolean(statusMenuAnchorEl) && menuJobId === job.id}
                    onClose={handleStatusMenuClose}
                    PaperProps={{
                      style: {
                        borderRadius: '8px',
                        marginTop: '0px',
                      },
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                  >
                    {STATUS_OPTIONS.map((status) => (
                      <MenuItem key={status} onClick={(event) => handleStatusChange(event, job.id, status)}>
                        {status}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: isSmallScreen ? 'flex-start' : 'center' }}>
                    <Box>
                      <Typography variant="body1" component="div" fontWeight="bold">
                        {job.company}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {job.positionTitle}
                      </Typography>
                      {job.salary && (
                        <Typography variant="body2" component="div" sx={{ color: 'gray' }}>
                          Salary: {truncateText(job.salary, 100)}
                        </Typography>
                      )}
                      {label != 'toreview' ? (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <div style={{ position: 'relative', display: 'inline-block', width: '200px' }}>
                            <Tooltip title={`${job.reviewEmails? job.reviewEmails.length : "0"} New Messages about this opportunity!`} placement="top">
                              <IconButton
                                size="medium"
                                sx={{ }}
                                onClick={(event) => { event.stopPropagation(); handleEmailIconClick(event, job); }}
                              >
                                <EmailIcon sx={{ color: job.reviewEmails?.length > 0 ? theme.palette.secondary.main : 'lightgray' }} />
                                {job.reviewEmails?.length > 0 && (
                                  <Chip
                                    size="small"
                                    sx={{ ml: 0, border: '', bgcolor: 'red' }}
                                    style={{
                                      color: '#fff',
                                      position: 'absolute',
                                      right: 6,
                                      top: 8,
                                      padding: '2px',
                                      borderRadius: '50%',
                                      width: '9px',
                                      height: '9px',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Outlook" placement="top">
                              <IconButton
                                size="small"
                                sx={{ ml: 2 }}
                                onClick={(event) => { event.stopPropagation(); handleOutlookClick(event, job); }}
                              >
                                {getOutlookIcon(job.outlook, theme)}
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={outlookMenuAnchorEl}
                              open={Boolean(outlookMenuAnchorEl) && selectedOutlookJob?.id === job.id}
                              onClose={(event) => { event.stopPropagation(); handleOutlookMenuClose(); }}
                              PaperProps={{
                                style: {
                                  borderRadius: '8px',
                                  marginTop: '0px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                },
                              }}
                              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            >
                              <MenuItem onClick={(event) => handleOutlookChange(event, job.id, 1)}>
                                <ThumbUpIcon sx={{ color: 'green' }} />
                              </MenuItem>
                              <MenuItem onClick={(event) => handleOutlookChange(event, job.id, 0)}>
                                <ThumbsUpDownIcon sx={{ color: 'gray' }} />
                              </MenuItem>
                              <MenuItem onClick={(event) => handleOutlookChange(event, job.id, -1)}>
                                <ThumbDownIcon sx={{ color: 'red' }} />
                              </MenuItem>
                            </Menu>
                            <Tooltip title="Notes" placement="top">
                              <IconButton
                                size="small"
                                sx={{ ml: 2 }}
                                onClick={(event) => { event.stopPropagation(); handleNoteClick(event, job); }}
                              >
                                <EditNoteIcon sx={{ color: job.personalNote ? theme.palette.secondary.main : 'lightgray' }} />
                                {job.personalNote && (
                                  <Chip
                                    size="small"
                                    sx={{ ml: 0, border: '', bgcolor: 'red' }}
                                    style={{
                                      color: '#fff',
                                      position: 'absolute',
                                      right: 1,
                                      top: 5,
                                      padding: '2px',
                                      borderRadius: '50%',
                                      width: '9px',
                                      height: '9px',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </Box>
                      ) : (
                        <Link href={job.postingUrl} target="_blank" rel="noopener noreferrer" underline="hover">
                          {job.postingUrl}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                {!isSmallScreen && <TableCell>{format(new Date(job.createdAt), 'MMMM d, yyyy')}</TableCell>}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={jobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ overflow: 'hidden' }}
      />
      {selectedNoteJob && (
        <JobNoteModal
          open={isNoteModalOpen}
          onClose={handleNoteModalClose}
          jobId={selectedNoteJob.id}
        />
      )}
      {selectedReviewEmails.length > 0 && (
        <ReviewEmailsDialog
          open={isReviewEmailsDialogOpen}
          onClose={handleReviewEmailsDialogClose}
          reviewEmails={selectedReviewEmails}
          jobData={selectedJob}
          refetchJobData={refetch}
        />
      )}
    </Paper>
    </>
  );
};

export default JobOpportunitiesTable;