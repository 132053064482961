import React, { useState } from 'react';
import { Box, Button, Divider, Chip, Typography, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, useMediaQuery, Link, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import SnackbarComponent from '../SnackbarComponent';
import { useRightDrawer } from '../../RightDrawerContext';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DiamondIcon from '@mui/icons-material/Diamond';

const ContactDetails = ({ formData, handleEditClick, deleteContact, refetch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { closeRightDrawer, rightDrawerData } = useRightDrawer();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteContact({ variables: { id: formData.id } });
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ mr: 2, height: 56, width: 56 }} src={formData.linkedinPhotoUrl || ''}>
            {!formData.linkedinPhotoUrl && `${formData.firstName[0]}${formData.lastName[0]}`}
          </Avatar>
          <Box>
            <Typography variant="h6">{formData.firstName} {formData.lastName}</Typography>
            <Typography variant="h7" color="textSecondary">{formData.company}</Typography>
            <Typography variant="h7" color="textSecondary">{formData.title}</Typography>
          </Box>
        </Box>
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleEditClick}>
            <EditIcon sx={{ mr: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography>Edit Contact</Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleDeleteClick} sx={{ color: 'red' }}>
            <DeleteIcon sx={{ mr: 1, color: 'red' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography>Delete Contact</Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </MenuItem>
        </Menu>
      </Box>
      <Grid container spacing={2}>
        {formData.email && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Email:</Typography>
          <Typography variant="body1">{formData.email || 'Not Available'}</Typography>
        </Grid>
        )}
        {formData.phone && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Phone:</Typography>
          <Typography variant="body1">{formData.phone || 'Not Available'}</Typography>
        </Grid>
        )}
        {formData.linkedinUrl && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">LinkedIn:</Typography>
          <Link href={formData.linkedinUrl} target="_blank" rel="noopener noreferrer">
            {formData.linkedinUrl || 'Not Available'}
          </Link>
        </Grid>
        )}
        {/* <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Notes:</Typography>
          {formData.notes && formData.notes.length > 0 ? (
            formData.notes.map((note, index) => (
              <Typography key={index} variant="body1">{note}</Typography>
            ))
          ) : (
            <Typography variant="body1">No Notes</Typography>
          )}
        </Grid> */}
        {/* <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Created At:</Typography>
          <Typography variant="body1">{formData.createdAt || 'Not Available'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">Updated At:</Typography>
          <Typography variant="body1">{formData.updatedAt || 'Not Available'}</Typography>
        </Grid> */}
      </Grid>
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactDetails;