import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import Box from '@mui/material/Box';

const NoteEditor = ({ value, onChange }) => {
  return (
    <Box sx={{ height: '80%' }}>
      <ReactQuill value={value} onChange={onChange} style={{ height: '100%' }} />
    </Box>
  );
};

export default NoteEditor;