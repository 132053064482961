import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, CircularProgress, Typography, Button, Divider } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GET_RESUME_FEEDBACK, GET_RESUMES } from '../queries';
import ModalHeader from '../ModalHeader';
import ResumeSelector from './ResumeSelector';
import JobDataDisplay from './JobDataDisplay';
import FeedbackSections from './FeedbackSections';
import SnackbarComponent from '../SnackbarComponent';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const WipeUpContainer = styled.div`
  &.fade {
    animation: ${fade} 0.5s forwards;
  }
`;

const ResumeAssist = ({ open, handleClose, jobData }) => {
  const [selectedResume, setSelectedResume] = useState('');
  const [resumes, setResumes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [wipeUp, setWipeUp] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [getResumes, { data: resumeData, loading: resumeLoading, error: resumeError }] = useLazyQuery(GET_RESUMES, {
    onCompleted: (data) => {
      if (data && data.getResumes) {
        setResumes(data.getResumes);
      }
    },
    onError: (error) => {
      console.error('Error fetching resumes:', error); // Log the error
    }
  });

  const [getResumeFeedback, { loading: feedbackLoading }] = useLazyQuery(GET_RESUME_FEEDBACK, {
    onCompleted: (data) => {
      setShowSpinner(false);
      setShowFeedback(true);
      setFeedback(data.getResumeFeedback);
    },
    onError: (error) => {
      console.error('Error getting resume feedback:', error); // Log the error
      setShowSpinner(false);
      // Handle error appropriately, e.g., show a snackbar or alert
    }
  });

  useEffect(() => {
    if (open) {
      getResumes(); // Fetch resumes when the modal is opened
    }
  }, [open]);

  const handleResumeChange = (event) => {
    setSelectedResume(event.target.value);
  };

  const handleProvideFeedbackClick = () => {
    setWipeUp(true);
    setTimeout(() => {
      setShowSpinner(true);
      const jobDetails = {
        jobDetails: {
            company: jobData.company,
            companyOverview: jobData.companyOverview,
            positionTitle: jobData.positionTitle,
            positionOverview: jobData.positionOverview,
            responsibilities: jobData.responsibilities,
            qualifications: jobData.qualifications
        }
      };
      const jsonJob = JSON.stringify(jobDetails, null, 2);
      getResumeFeedback({
        variables: {
          resumeId: selectedResume,
          jobId: jobData.id
        }
      });
    }, 500); // Time for wipe up animation
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiDialog-paper': {
            width: '90%',
            height: 'auto',
            margin: 'auto',
            pb: 2,
            overflow: 'visible', // Ensure overflow is visible
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <ModalHeader />
            <Typography variant="h6" sx={{ mt: 3 }}>AI Resume Assistant</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: 'auto', overflow: 'scroll' }}>
          {(!showSpinner && !showFeedback) && (
            <>
              <WipeUpContainer className={wipeUp ? 'wipe-up' : ''}>
                <Typography variant="body2" component="div" sx={{ mt: 1 }}>
                  <b>Resume Feedback Assistant</b> will help you prepare for your upcoming interview by analyzing the job description and your resume to provide feedback on how well your resume aligns with the job requirements.
                </Typography>
                <ResumeSelector
                  resumes={resumes}
                  selectedResume={selectedResume}
                  handleResumeChange={handleResumeChange}
                  loading={resumeLoading}
                />
              </WipeUpContainer>
            </>
          )}
                    <JobDataDisplay jobData={jobData} />
          {showSpinner && (
            <>
              <Divider sx={{ mt: 2, mb: 2 }}><Typography variant="h6">Analyzing</Typography></Divider>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            </>
          )}
          <FeedbackSections feedback={feedback} />
          {(!showSpinner && !showFeedback) && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleProvideFeedbackClick}
            >
              Provide Resume Feedback
            </Button>
          )}
        </DialogContent>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message="Comparison successful!"
      />
    </div>
  );
};

export default ResumeAssist;