import React, { useState, useContext } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { useSnackbar } from '../../SnackbarContext';
import JobForm from './JobForm';
import JobDetails from './JobDetails';
import { AppContext } from '../../AppContext'; // Update this line
import { CREATE_JOB_OPPORTUNITY, UPDATE_JOB_OPPORTUNITY, DELETE_JOB_OPPORTUNITY } from '../queries';

const JobOpportunityContent = (props) => {
  const { 
    id, 
    group, 
    company, 
    companyOverview, 
    positionTitle, 
    positionOverview, 
    companyLocation, 
    workingLocation, 
    employmentType, 
    benefits, 
    responsibilities, 
    qualifications, 
    travelRequirements, 
    website, 
    salary, 
    processStatus, 
    dateApplied, 
    postingUrl 
  } = props;

  const isAddingNew = !id;
  const { globalOnSave } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(isAddingNew);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ 
    id, 
    group, 
    company, 
    companyOverview, 
    positionTitle, 
    positionOverview, 
    companyLocation, 
    workingLocation, 
    employmentType, 
    benefits, 
    responsibilities, 
    qualifications, 
    travelRequirements, 
    website, 
    salary, 
    processStatus, 
    dateApplied, 
    postingUrl 
  });

  const [originalData, setOriginalData] = useState({ 
    id, 
    group, 
    company, 
    companyOverview, 
    positionTitle, 
    positionOverview, 
    companyLocation, 
    workingLocation, 
    employmentType, 
    benefits, 
    responsibilities, 
    qualifications, 
    travelRequirements, 
    website, 
    salary, 
    processStatus, 
    dateApplied, 
    postingUrl 
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  console.log("Props Main", props);
  const [createJobOpportunity] = useMutation(CREATE_JOB_OPPORTUNITY, {
    onCompleted: () => {
      setIsEditing(false);
      showSnackbar({ message: 'New job opportunity created!', severity: 'success' });
      globalOnSave();
      console.log("New job opportunity created!");
      props.closeDrawer();
    },
    onError: (error) => {
      console.error('Error creating job opportunity:', error);
    }
  });

  const [updateJobOpportunity] = useMutation(UPDATE_JOB_OPPORTUNITY, {
    onCompleted: () => {
      showSnackbar({ message: 'Job opportunity updated!', severity: 'success' });
      setIsEditing(false);
      props.refetch && props.refetch();
      props.closeDrawer();
    },
    onError: (error) => {
      console.error('Error updating job opportunity:', error);
    }
  });



  const handleEditClick = () => {
    setIsEditing(true);
  };
  console.log(props);
  const [deleteJobOpportunity] = useMutation(DELETE_JOB_OPPORTUNITY, {
    onCompleted: () => {
      showSnackbar({ message: 'Job opportunity Deleted!', severity: 'success' });
      console.log(props.refetch);
      props.refetch && props.refetch();
      props.closeDrawer();
     // refetch(); // Trigger a refetch of the job opportunities
      //props.closeDrawer; // Close the right drawer
      
    },
    onError: (error) => {
      console.error('Error deleting job opportunity:', error);
    }
  });

  const handleSaveClick = () => {
    console.log('Input data being sent:', formData);
    if (id) {
      updateJobOpportunity({ variables: { input: { id, ...formData } } });
    } else {
      createJobOpportunity({ variables: { input: formData } });
    }
  };

  const handleCancelClick = () => {
    if(id) {
      if (JSON.stringify(formData) !== JSON.stringify(originalData)) {
        setDiscardDialogOpen(true);
      } else {
        setIsEditing(false);
      }
    } else {
      props.closeDrawer();
    }
  };

  const handleDiscardChanges = () => {
    setFormData(originalData);
    setIsEditing(false);
    setDiscardDialogOpen(false);
  };

  const handleCloseDiscardDialog = () => {
    setDiscardDialogOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box sx={{ padding: 2 }}>
      {isEditing ? (
        <JobForm
          formData={formData}
          handleChange={handleChange}
          handleSaveClick={handleSaveClick}
          handleCancelClick={handleCancelClick}
          
        />
      ) : (
        <JobDetails
          formData={formData}
          isExpanded={props.isExpanded}
          handleEditClick={handleEditClick}
          deleteJobOpportunity={deleteJobOpportunity}
        />
      )}
      <Dialog
        open={discardDialogOpen}
        onClose={handleCloseDiscardDialog}
      >
        <DialogTitle>Discard changes?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Discard changes to this opportunity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDiscardDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDiscardChanges} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobOpportunityContent;