// src/components/EventFormModal.js
import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SnackbarComponent from './SnackbarComponent';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EventFormModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    start: null,
    end: null,
    title: '',
    notes: '',
    meetingUrl: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      start: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      end: date,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSnackbarOpen(true);
    handleClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-event-form"
        aria-describedby="modal-event-form-description"
      >
        <Box sx={style}>
          <Typography id="modal-event-form" variant="h6" component="h2">
            Add Event
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <DateTimePicker
              label="Start"
              value={formData.start}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
            />
            <DateTimePicker
              label="End"
              value={formData.end}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
            />
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              fullWidth
              label="Meeting URL"
              name="meetingUrl"
              value={formData.meetingUrl}
              onChange={handleChange}
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message="Event added successfully!"
        link="/calendar"
      />
    </div>
  );
};

export default EventFormModal;