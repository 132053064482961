import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, TextField, Button, Typography, Grid, CircularProgress, MenuItem } from '@mui/material';
import { GET_USER, UPDATE_USER } from './queries'; // Ensure you have these queries/mutations defined
import languages from '../data/languages.json'; // Import the languages data

const ProfileForm = () => {
  const [loadUser, { data, loading, error }] = useLazyQuery(GET_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    email: '',
    gender: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    preferredLanguage: '',
    timeZone: ''
  });

  useEffect(() => {
    loadUser(); // Trigger the query to load the user data
  }, [loadUser]);
  
  useEffect(() => {
    if (data && data.getUser) {
      setFormData({
        firstName: data.getUser.firstName || '',
        lastName: data.getUser.lastName || '',
        phoneNumber: data.getUser.phoneNumber || '',
        dateOfBirth: data.getUser.dateOfBirth || '',
        gender: data.getUser.gender || '',
        email:  data.getUser.email || '',
        address: data.getUser.address || {
          street: '',
          city: '',
          state: '',
          zip: '',
          country: ''
        },
        preferredLanguage: data.getUser.preferredLanguage || '',
        timeZone: data.getUser.timeZone || ''
      });
    }
  }, [data]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in formData.address) {
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value
        }
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser({
        variables: {
          input: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            dateOfBirth: formData.dateOfBirth,
            gender: formData.gender,
            address: formData.address,
            preferredLanguage: formData.preferredLanguage,
            timeZone: formData.timeZone
          }
        }
      });
      alert('Profile updated successfully');
    } catch (err) {
      console.error('Failed to update profile:', err);
      alert('Failed to update profile');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography>Error loading profile: {error.message}</Typography>;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'left' }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: '600px', textAlign: 'left' }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              value={formData.email}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              select
              fullWidth
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Street"
              name="street"
              value={formData.address.street}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="City"
              name="city"
              value={formData.address.city}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State"
              name="state"
              value={formData.address.state}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="ZIP Code"
              name="zip"
              value={formData.address.zip}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              name="country"
              value={formData.address.country}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Preferred Language"
              name="preferredLanguage"
              value={formData.preferredLanguage}
              onChange={handleChange}
              select
              fullWidth
            >
              {languages.map((language) => (
                <MenuItem key={language.code} value={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Time Zone"
              name="timeZone"
              value={formData.timeZone}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileForm;