import React from 'react';
import { FormControl, Select, InputLabel, MenuItem, Divider, Typography } from '@mui/material';
import tones from './coverLetterTones.json'; // Adjust the path to your JSON file

const ResumeSelector = ({ resumes, selectedResume, handleResumeChange, loading, selectedTone, handleToneChange }) => (
  <div>
    <FormControl fullWidth margin="normal" sx={{ mt: 3 }}>
      <InputLabel id="resume-select-label" sx={{
          backgroundColor: '#fff',
          padding: '0 4px',
        }}>Select Resume</InputLabel>
      <Select
        labelId="resume-select-label"
        value={selectedResume}
        onChange={handleResumeChange}
        disabled={loading}
      >
        {resumes.filter(resume => resume.resumeStatus === "Ready").map((resume) => (
          <MenuItem key={resume.id} value={resume.id}>
            {resume.title} {/* Ensure this matches the actual field name for resume titles */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {selectedResume && (
      <FormControl fullWidth margin="normal">
        <InputLabel id="tone-select-label"  sx={{
          backgroundColor: '#fff',
          padding: '0 4px',
        }}>Select Tone</InputLabel>
        <Select
          labelId="tone-select-label"
          value={selectedTone}
          onChange={handleToneChange}
        >
          {tones.tones.map((tone) => (
            <MenuItem key={tone.name} value={tone.name}>
              {tone.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    <Divider sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h6">Cover Letter For</Typography>
    </Divider>
  </div>
);

export default ResumeSelector;