import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, TextField, Grid, Box, Typography, Container, CssBaseline, Link } from '@mui/material';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from '../config';

const poolData = {
  UserPoolId: config.cognito.USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const email = location.state?.email || '';

  const handleSubmit = (event) => {
    event.preventDefault();

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      setSuccess('Verification successful! You can now sign in.');
      setTimeout(() => navigate('/signin'), 2000); // Redirect after 2 seconds
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box  
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          marginBottom: 0

        }}
      >
      <img src="/JoboomiTextPurple.svg" alt="Joboomi logo" style={{ width: '70%' }} />
      </Box>
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f5f5f5 ',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Typography component="h1" variant="h5">
          Verify Your Account
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          Please enter the verification code sent to your email address.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="verificationCode"
            label="Verification Code"
            name="verificationCode"
            autoComplete="off"
            autoFocus
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Verify
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Verify;