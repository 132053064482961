import React, { createContext, useContext, useState } from 'react';

const RightDrawerContext = createContext();

export const RightDrawerProvider = ({ children }) => {
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [rightDrawerContentType, setRightDrawerContentType] = useState(null);
  const [rightDrawerContent, setRightDrawerContent] = useState({});
  const [rightDrawerRefetch, setRightDrawerRefetch] = useState(null);

  const openRightDrawer = (type, content, refetch) => {
    setRightDrawerContentType(type);
    setRightDrawerContent(content);
    setRightDrawerRefetch(() => refetch || null);
    console.log("REFETCH CONTEXT", refetch);
    setRightDrawerOpen(true);
  };

  const closeRightDrawer = () => {
    setRightDrawerOpen(false);
  };

  return (
    <RightDrawerContext.Provider value={{
      rightDrawerOpen,
      rightDrawerContentType,
      rightDrawerContent,
      rightDrawerRefetch,
      openRightDrawer,
      closeRightDrawer,
    }}>
      {children}
    </RightDrawerContext.Provider>
  );
};

export const useRightDrawer = () => useContext(RightDrawerContext);