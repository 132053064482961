// src/components/Signin.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, TextField, Grid, Box, Typography, Container, CssBaseline, Link } from '@mui/material';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../config';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { AppContext } from '../AppContext';
import { UPDATE_USER_LOGIN } from './queries';

const poolData = {
  UserPoolId: config.cognito.USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
};

const GET_USER = gql`
  query GetUser {
    getUser {
      id
      subscriptionPlan
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      preferredLanguage
    }
  }
`;

const userPool = new CognitoUserPool(poolData);

const Signin = () => {
  const { setUser } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [getUser] = useLazyQuery(GET_USER);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUserLogin] = useMutation(UPDATE_USER_LOGIN);

  const handleSubmit = (event) => {
    event.preventDefault();

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        console.log('access token + ' + result.getAccessToken().getJwtToken());
        const cognitoSub = result.getIdToken().payload.sub;
        console.log('cognitoSub: ' + cognitoSub);
        try {
          const { data } = await getUser();
          if (!data.getUser) {
            const { data: createData } = await createUser({
              variables: {
                input: {
                  preferredLanguage: (navigator.language || navigator.userLanguage).split('-')[0],
                },
              },
            });
            setUser({
              ...result.getIdToken().decodePayload(),
              ...createData.createUser
            });
            await updateUserLogin();
          } else {
            await updateUserLogin();
            setUser({
              ...result.getIdToken().decodePayload(),
              ...data.getUser
            });
          }
          navigate('/job-opportunities');
        } catch (err) {
          setError(err.message);
        }
      },
      onFailure: (err) => {
        setError(err.message);
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box  
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          marginBottom: 0
        }}
      >
      <img src="/JoboomiTextPurple.svg" alt="Joboomi logo" style={{ width: '70%' }} />
      </Box>
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In to supercharge your career:
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signup" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Signin;