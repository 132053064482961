import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import lightTheme from './themes/lightTheme';
import purpleTheme from './themes/purpleTheme';
import darkTheme from './themes/darkTheme';

const themes = {
  light: lightTheme,
  dark: darkTheme,
  purple: purpleTheme,
};

const CustomThemeContext = createContext();

export const useCustomTheme = () => useContext(CustomThemeContext);

const CustomThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState('purple');

  const theme = useMemo(() => themes[themeName], [themeName]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('appTheme');
    if (savedTheme && themes[savedTheme]) {
      setThemeName(savedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('appTheme', themeName);
  }, [themeName]);

  const switchTheme = (newTheme) => {
    if (themes[newTheme]) {
      setThemeName(newTheme);
    }
  };

  const setTheme = (name) => {
    if (themes[name]) {
      setThemeName(name);
    } else {
      console.warn(`Theme ${name} does not exist`);
    }
  };

  return (
    <CustomThemeContext.Provider value={{ theme, setTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;