import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, Box, Divider, Typography, Card, CardContent, CardActions, Button, Grid } from '@mui/material';
import ZoomIcon from '@mui/icons-material/ZoomOutMap';
import TeamsIcon from '@mui/icons-material/Group';
import { differenceInDays, differenceInWeeks, differenceInMonths, format } from 'date-fns';

const Calendar = () => {
  const [groupedEvents, setGroupedEvents] = useState({});
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const fetchEvents = async () => {
    const response = await fetch('/api/calendar');
    const data = await response.json();
    setGroupedEvents(data.groupedEvents);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const getCountdown = (date) => {
    const now = new Date();
    const eventDate = new Date(date);

    const days = differenceInDays(eventDate, now);
    if (days < 7) return `In ${days} days`;

    const weeks = differenceInWeeks(eventDate, now);
    if (weeks < 4) return `In about ${weeks} weeks`;

    const months = differenceInMonths(eventDate, now);
    return `In about ${months} months`;
  };

  return (
    <> 
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '66px' }}>
          <Typography variant='h6'>Calendar</Typography>
        
          
        </Box>
        {!isMobile && (
          <Box sx={{ position: 'relative' }}>
            <Divider sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              transform: 'translateX(-50%)',
              marginLeft: '50%',
            }} />
          </Box>
        )}
      </div>
      {Object.entries(groupedEvents).map(([monthYear, events]) => (
        <div key={monthYear} style={{ marginBottom: 32 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {new Date(events[0].date).toLocaleString('default', { month: 'long', year: 'numeric' })}
          </Typography>
          <Grid container spacing={2}>
            {events.map((event, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {event.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {format(new Date(event.date), 'EEEE, MMMM d')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {getCountdown(event.date)}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      href={event.link.url}
                      target="_blank"
                      startIcon={event.link.platform === 'Zoom' ? <ZoomIcon /> : <TeamsIcon />}
                    >
                      {event.link.platform} Meeting
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </>
  );
};

export default Calendar;
