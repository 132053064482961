import React from 'react';
import { Avatar } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

const CompanyAvatar = ({ website, smallscreen }) => {
  const token = 'pk_YGrzCrihQpCkNF9UvE7DAQ'; // Replace with your actual token
  const cleanWebsite = website ? website.replace(/^https?:\/\//, '') : null;

  return (
    <Avatar sx={{ bgcolor: 'gray(300)', borderWidth: '1px', borderColor: 'lightgray', borderStyle: 'solid', width: (smallscreen ? '40px' : '50px'), height: (smallscreen ? '40px' : '50px') }}>
      {cleanWebsite ? (
        <img 
          src={`https://img.logo.dev/${cleanWebsite}?token=${token}`} 
          alt="Company Logo" 
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <BusinessIcon />
      )}
    </Avatar>
  );
};

export default CompanyAvatar;