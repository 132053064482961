import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import JobOpportunities from './pages/JobOpportunities';
import Contacts from './pages/Contacts';
import Calendar from './pages/Calendar';
import Resources from './pages/Resources';
import Portfolio from './pages/Portfolio';
import Settings from './pages/Settings';
import Subscription from './pages/Subscription';
import ProfileEdit from './pages/ProfileEdit';

import MainLayout from './components/MainLayout';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Verify from './components/Verify';
import PrivateRoute from './components/PrivateRoute';
import SpeedDialComponent from './components/SpeedDialComponent';
import LoadingSpinner from './components/LoadingSpinner';

import { RightDrawerProvider } from './RightDrawerContext';
import { SnackbarProvider } from './SnackbarContext';
import { AppProvider, AppContext } from './AppContext';
import { GOOGLE_CLIENT_ID } from './config';

const App = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log("App.js Rendered");

  return (
    <AppProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <LoadingSpinner open={false} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider>
            <RightDrawerProvider>
              <AppContext.Consumer>
                {({ userLoading }) => (
                  userLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <Routes>
                      <Route path="/signin" element={<SignIn />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/verify" element={<Verify />} />
                      <Route
                        path="/*"
                        element={
                          <PrivateRoute>
                            <MainLayout>
                              <Routes>
                                <Route path="/" element={<Navigate to="/job-opportunities" />} />
                                <Route path="job-opportunities" element={<JobOpportunities />} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="portfolio" element={<Portfolio />} />
                                <Route path="resources" element={<Resources />} />
                                <Route path="contacts" element={<Contacts />} />
                                <Route path="calendar" element={<Calendar />} />
                                <Route path="profile-edit" element={<ProfileEdit />} />
                                <Route path="subscription" element={<Subscription />} />
                              </Routes>
                              <SpeedDialComponent />
                            </MainLayout>
                          </PrivateRoute>
                        }
                      />
                    </Routes>
                  )
                )}
              </AppContext.Consumer>
            </RightDrawerProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </AppProvider>
  );
};

export default App;