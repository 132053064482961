import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, CircularProgress, Typography, Button, Divider } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GET_COVER_LETTER, GET_RESUMES } from '../queries';
import ModalHeader from '../ModalHeader';
import ResumeSelector from './ResumeSelector';
import JobDataDisplay from './JobDataDisplay';
import FeedbackSections from './FeedbackSections';
import SnackbarComponent from '../SnackbarComponent';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const WipeUpContainer = styled.div`
  &.fade {
    animation: ${fade} 0.5s forwards;
  }
`;

const CoverLetterAssist = ({ open, handleClose, jobData }) => {
  const [selectedResume, setSelectedResume] = useState('');
  const [selectedTone, setSelectedTone] = useState('');
  const [resumes, setResumes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [wipeUp, setWipeUp] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [getResumes, { data: resumeData, loading: resumeLoading, error: resumeError }] = useLazyQuery(GET_RESUMES, {
    onCompleted: (data) => {
      if (data && data.getResumes) {
        setResumes(data.getResumes);
      }
    },
    onError: (error) => {
      console.error('Error fetching resumes:', error);
    }
  });

  const [getCoverLetter, { loading: feedbackLoading }] = useLazyQuery(GET_COVER_LETTER, {
    onCompleted: (data) => {
      setShowSpinner(false);
      setShowFeedback(true);
      setFeedback(data.getCoverLetter);
    },
    onError: (error) => {
      console.error('Error getting cover letter:', error);
      setShowSpinner(false);
    }
  });

  useEffect(() => {
    if (open) {
      getResumes();
    }
  }, [open]);

  const handleResumeChange = (event) => {
    setSelectedResume(event.target.value);
  };

  const handleToneChange = (event) => {
    setSelectedTone(event.target.value);
  };

  const handleProvideFeedbackClick = () => {
    setWipeUp(true);
    setTimeout(() => {
      setShowSpinner(true);
      getCoverLetter({
        variables: {
          resumeId: selectedResume,
          jobDetails: `${jobData.companyOverview}\n${jobData.positionOverview}\n${jobData.positionTitle}\n${jobData.qualifications}`,
          tone: selectedTone,
        }
      });
    }, 500);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiDialog-paper': {
            width: '90%',
            height: 'auto',
            margin: 'auto',
            pb: 2,
            overflow: 'visible', // Ensure overflow is visible
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center ', width: '100%' }}>
            <ModalHeader />
            <Typography variant="h6" sx={{ mt: 3 }}>Cover Letter Assistant</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: 'auto', overflow: 'visible' }}>
          {(!showSpinner && !showFeedback) && (
            <>
              <WipeUpContainer className={wipeUp ? 'wipe-up' : ''}>
                <Typography variant="body2" component="div" sx={{ mt: 1 }}>
                  <b>Cover Letter Assistant</b> will help you create a cover letter that stands out to employers targeted at this specific job based on your selected resume.
                </Typography>
                <ResumeSelector
                  resumes={resumes}
                  selectedResume={selectedResume}
                  handleResumeChange={handleResumeChange}
                  loading={resumeLoading}
                  selectedTone={selectedTone}
                  handleToneChange={handleToneChange}
                />
              </WipeUpContainer>
            </>
          )}
                    <JobDataDisplay jobData={jobData} />
          {showSpinner && (
            <>
              <Divider sx={{ mt: 2, mb: 2 }}><Typography variant="h6">Analyzing</Typography></Divider>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            </>
          )}
          <FeedbackSections feedback={feedback} />
          {(!showSpinner && !showFeedback) && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleProvideFeedbackClick}
            >
              Create Cover Letter
            </Button>
          )}
        </DialogContent>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message="Comparison successful!"
      />
    </div>
  );
};

export default CoverLetterAssist;