import React, { useState, useContext } from 'react';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  styled,
  Box,
  Typography,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PaletteIcon from '@mui/icons-material/Palette';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ThemeSwitcher from '../ui/ThemeSwitcher';
import { AppContext } from '../AppContext';

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: '2px solid white',
  backgroundColor: '#a793f0',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#c3c3c3',
  },
  width: 36, 
  height: 36
}));

const UserMenu = ({ drawerOpen }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [profileOpen, setProfileOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [themeAnchorEl, setThemeAnchorEl] = useState(null);
  const [chromeExtensionDialogOpen, setChromeExtensionDialogOpen] = useState(false);
  
  const { user, logout } = useContext(AppContext);

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleProfileOpen = () => {
    setProfileOpen(true);
    handleMenuClose();
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
    handleMenuClose();
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleLogoutOpen = () => {
    setLogoutOpen(true);
    handleMenuClose();
  };

  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };

  const handleLogoutConfirm = () => {
    logout();
  };

  const handleEditProfile = () => {
    setProfileOpen(false);
    navigate('/profile-edit');
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleThemeMenuOpen = (event) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleThemeMenuClose = () => {
    setThemeAnchorEl(null);
  };

  const isChromeOnDesktop = () => {
    const userAgent = navigator.userAgent;
    const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    const isDesktop = window.innerWidth > 768;
    return isChrome && isDesktop;
  };
  
  
  console.log("Is Chrome:", isChromeOnDesktop()); // Debugging line to check the result

  const userName = user?.email?.split('@')[0];
  const userEmailDomain = user?.email?.split('@')[1];
  
  if (!user) {
    return <CircularProgress />;
  }

  console.log("User in UserMenu", user);
  return (
    <>
      <IconButton color="inherit" onClick={handleAvatarClick}>
        <CustomAvatar alt={userName} src={user?.profilePictureUrl} />
        {!isMobile && drawerOpen && (
          <>
            <Typography sx={{ ml: 1, color: '#fff', fontSize: '0.7em' }}>{userName}</Typography>
          </>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: 0,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          Account
        </MenuItem>
        
        <MenuItem onClick={handleThemeMenuOpen}>
          <ListItemIcon>
            <PaletteIcon />
          </ListItemIcon>
          Change Theme
        </MenuItem>
        <Menu
          anchorEl={themeAnchorEl}
          open={Boolean(themeAnchorEl)}
          onClose={handleThemeMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '50%',
                left: 0,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
        >
          <ThemeSwitcher />
        </Menu>
        
        {isChromeOnDesktop() && (
          <MenuItem onClick={() => { setChromeExtensionDialogOpen(true); handleMenuClose(); }}>
            <ListItemIcon>
              <SettingsIcon /> {/* Change icon if needed */}
            </ListItemIcon>
            Install Chrome Extension
          </MenuItem>
        )}
        
        <MenuItem onClick={handleLogoutOpen}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>

      {/* Profile Dialog */}
      <Dialog open={profileOpen} onClose={handleProfileClose}>
        <DialogTitle>Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Display user info here */}
            Email: {user?.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileClose}>Close</Button>
          <Button onClick={handleEditProfile}>Edit Profile</Button>
        </DialogActions>
      </Dialog>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={handleSettingsClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Placeholder content for settings */}
            Settings content goes here.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSettingsClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Logout Confirmation Dialog */}
      <Dialog open={logoutOpen} onClose={handleLogoutClose}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutClose}>Cancel</Button>
          <Button onClick={handleLogoutConfirm} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={chromeExtensionDialogOpen} onClose={() => setChromeExtensionDialogOpen(false)}>
  <DialogTitle>
  <LanguageIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
    Install Chrome Extension
  </DialogTitle>
  <DialogContent>
    <DialogContentText>
      This extension is currently in Development Mode and not available on the Chrome Web Store. To install it, please follow these steps:
      <ol>
        <li>Download the Chrome extension: <a href="https://drive.google.com/drive/folders/1-6nfVDNtoP8MXwXAhnndLy-QU_rt3vNr" target="_blank" rel="noopener noreferrer">Joboomi Extension</a></li>
        <li>Unzip the file, and you will find a folder named "dist".</li>
        <li>In Chrome, navigate to <strong>Window &gt; Extensions</strong> (or Manage Extensions).</li>
        <li>Enable the "Developer Mode" toggle in the top right corner.</li>
        <li>Click the “Load Unpacked” button in the top left corner and select the "dist" folder you just unzipped.</li>
        <li>Consider pinning the extension for easier access.</li>
      </ol>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setChromeExtensionDialogOpen(false)}>Close</Button>
  </DialogActions>
</Dialog>
    </>
  );
};

export default UserMenu;