import React from 'react';
import { Box, Typography } from '@mui/material';
import CompanyAvatar from '../CompanyAvatar';

const JobDataDisplay = ({ jobData }) => (
  jobData && (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 1 }}>
      <CompanyAvatar website={jobData.website} />
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1" component="div" fontWeight="bold">
          {jobData.company}
        </Typography>
        <Typography variant="body2" component="div">
          {jobData.positionTitle}
        </Typography>
      </Box>
    </Box>
  )
);

export default JobDataDisplay;