import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Alert, Box, AlertTitle, IconButton, Badge, Popover, List, ListItem, Button, Typography, Link } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material/styles';
import { GET_ALERTS, DELETE_ALERT, DELETE_ALL_ALERTS } from './queries';
import { formatDistanceToNow } from 'date-fns';

const formatCreatedAt = (createdAt) => {
  return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
};

const AlertWindow = ({ sx, mobile }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_ALERTS, {
    fetchPolicy: 'network-only', // Ensure fresh data on each load
  });

  const [deleteAlert] = useMutation(DELETE_ALERT);
  const [deleteAllAlerts] = useMutation(DELETE_ALL_ALERTS);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(); // Periodically refetch the alerts
    }, 60000); // Refetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [refetch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAlert = async (id) => {
    try {
      await deleteAlert({ variables: { id } });
      refetch();
    } catch (err) {
      console.error('Error deleting alert:', err);
    }
  };

  const handleDeleteAllAlerts = async () => {
    try {
      await deleteAllAlerts();
      refetch();
    } catch (err) {
      console.error('Error deleting all alerts:', err);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading alerts</div>;

  // Create a copy of the alerts array and then sort it
  const alerts = [...(data?.getAlerts || [])].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div>
      <IconButton onClick={handleClick} sx={sx} style={{ color: (!mobile ? theme.palette.primary : theme.palette.primary.contrastText) }}>
        <Badge badgeContent={alerts.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List sx={{
          width: '350px',
          maxHeight: '500px',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}>
          {alerts.length > 0 ? (
            <>
              {alerts.map(alert => (
                <ListItem key={alert.id} sx={{ ml: '2px', mr: '2px', width: '100%' }}>
                  <Alert severity='info' sx={{ width: '100%', fontSize: '0.8rem' }}>
                    <AlertTitle sx={{ fontSize: '0.8rem', fontWeight: 'bold', width: '100%' }}>{alert.alertTitle}</AlertTitle>
                    {alert.alertMessage}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="body2" color="textSecondary" sx={{ width: '100%' }}>
                        {formatCreatedAt(alert.createdAt)}
                      </Typography>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteAlert(alert.id);
                        }}
                        variant="body2"
                        color="primary"
                        sx={{  ml: 2 }}
                      >
                        Clear
                      </Link>
                    </Box>
                  </Alert>
                </ListItem>
              ))}
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 1 }}>
                <Button
                  onClick={handleDeleteAllAlerts}
                  variant="contained"
                  color="primary"
                  sx={{ fontSize: '0.8rem', padding: '6px 12px' }} // Smaller size for the button
                >
                  Clear All Alerts
                </Button>
              </Box>
            </>
          ) : (
            <Typography sx={{ textAlign: 'center', p: 2 }}>
              You have no new alerts
            </Typography>
          )}
        </List>
      </Popover>
    </div>
  );
};

export default AlertWindow;