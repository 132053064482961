import React, { useState, useContext } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ContactsIcon from '@mui/icons-material/Contacts';
import EventIcon from '@mui/icons-material/Event';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import JobFormModal from './JobFormModal';
import ContactFormModal from './ContactFormModal';
import EventFormModal from './EventFormModal';
import { AppContext } from '../AppContext'; // Update this line
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const actions = [
  { icon: <EventIcon />, name: 'Add Event', modal: 'event' },
  { icon: <ContactsIcon />, name: 'Add Contact', modal: 'contact' },
  { icon: <WhatshotIcon />, name: 'Add Opportunity', modal: 'job' },
];

const SpeedDialComponent = () => {
  const { globalOnSave } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenModal = (modal) => {
    console.log('Opening modal:', modal);
    console.log('onSave function in SpeedDialComponent:', globalOnSave);
    setOpenModal(modal);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: 'fixed', bottom: isSmallScreen ? 80 : 60, right: isSmallScreen ? 20 : 80 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={false}
            // TooltipProps={{ disableHoverListener: isSmallScreen }}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem', // Increase the icon size proportionally
              },
            }}
            onClick={() => handleOpenModal(action.modal)}
          />
        ))}
      </SpeedDial>

      <JobFormModal open={openModal === 'job'} handleClose={handleCloseModal} onSave={globalOnSave} />
      <ContactFormModal open={openModal === 'contact'} handleClose={handleCloseModal} onSave={globalOnSave} />
      <EventFormModal open={openModal === 'event'} handleClose={handleCloseModal} onSave={globalOnSave} />
    </div>
  );
};

export default SpeedDialComponent;