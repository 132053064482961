import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './assets/Joboomi48.png';
import { useTheme } from '@mui/material/styles';
import { useCustomTheme } from '../ui/CustomThemeProvider';
import UserMenu from './UserMenu'; // Import UserMenu
import SearchBar from './SearchBar';
import AlertWindow from './AlertWindow';

const HeaderAppBarMobile = ({ handleDrawerToggle }) => {
  const { theme } = useCustomTheme();
  const muiTheme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: muiTheme.zIndex.main + 1,
        height: { xs: '56px', md: '64px' },
        backgroundColor: theme.palette.primary.drawerBackground,
        
        width: '100vw'
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Joboomi Logo" style={{ height: '35px', width: '35px'  }} />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <SearchBar sx={{ mr: '20px' }} />
          <AlertWindow sx={{ mr: '10px' }} mobile="mobile" />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderAppBarMobile;