import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, TextField, Grid, Box, Typography, Container, CssBaseline, Link } from '@mui/material';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from '../config';

const poolData = {
  UserPoolId: config.cognito.USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    userPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        if (err.code === 'UsernameExistsException') {
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
          });
          cognitoUser.resendConfirmationCode((err) => {
            if (err) {
              setError(err.message || JSON.stringify(err));
            } else {
              navigate('/verify', { state: { email } });
            }
          });
        } else {
          setError(err.message || JSON.stringify(err));
        }
      } else {
        navigate('/verify', { state: { email } });
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box  
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          marginBottom: 0

        }}
      >
      <img src="/JoboomiTextPurple.svg" alt="Joboomi logo" style={{ width: '70%' }} />
      </Box>
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f5f5f5 ',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Typography component="p" sx={{ mt: 1, mb: 2 }}>
          Please enter your email and password to create your account.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Signup;