import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ResourcesIcon from '@mui/icons-material/LibraryBooks';
import ContactsIcon from '@mui/icons-material/Contacts';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WorkIcon from '@mui/icons-material/Work';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import FolderIcon from '@mui/icons-material/Folder';

const BottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={value} onChange={handleChange} showLabels sx={{ height: '70px', pt: "2px", pb: '10px' }}>
        <BottomNavigationAction label="Jobs" value="job-opportunities" icon={<WhatshotIcon />} />
        <BottomNavigationAction label="Portfolio" value="portfolio" icon={<FolderIcon />} />
        {/* <BottomNavigationAction label="Resources" value="resources" icon={<ResourcesIcon />} /> */}
        <BottomNavigationAction label="Contacts" value="contacts" icon={<ContactsIcon />} />
        <BottomNavigationAction label="Calendar" value="calendar" icon={<CalendarTodayIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;