import React from 'react';
import { useCustomTheme } from './CustomThemeProvider';
import { Box, IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const CircleButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  margin: '0 8px',
  border: '2px solid transparent',
  '&:not(.active)': {
    borderColor: '#ccc',
  },
  '&.active': {
    borderColor: 'red',
  },
}));

const SwitcherContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const ThemeSwitcher = () => {
  const { setTheme, theme } = useCustomTheme();
  return (
    <SwitcherContainer>
      <MenuItem>
        <CircleButton
          onClick={() => setTheme('light')}
          style={{ backgroundColor: '#f5f5f5' }}
          className={theme.themeName === 'light' ? 'active' : ''}
        />
      </MenuItem>
      <MenuItem>
        <CircleButton
          onClick={() => setTheme('dark')}
          style={{ backgroundColor: '#121212' }}
          className={theme.themeName === 'dark' ? 'active' : ''}
        />
      </MenuItem>
      <MenuItem>
        <CircleButton
          onClick={() => setTheme('purple')}
          style={{ backgroundColor: '#78568F' }} // Lightened purple color
          className={theme.themeName === 'purple' ? 'active' : ''}
        />
      </MenuItem>
    </SwitcherContainer>
  );
};

export default ThemeSwitcher;