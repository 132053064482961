import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import config from './config';

const poolData = {
  UserPoolId: config.cognito.USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

const getUserSub = () => {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return resolve(null); // Return null if no current user
    }

    currentUser.getSession((err, session) => {
      if (err) {
        return reject(err);
      }

      resolve(session.getIdToken().decodePayload().sub);
    });
  });
};

const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return reject(new Error('No current user'));
    }

    currentUser.getSession((err, session) => {
      if (err) {
        return reject(err);
      }

      resolve(session.getIdToken().getJwtToken());
    });
  });
};

export { getCurrentUser, getUserSub, getIdToken };