import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  Link,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDistanceToNow } from 'date-fns';
import ModalHeader from './ModalHeader';
import CompanyAvatar from './CompanyAvatar';
import { styled } from '@mui/system';
import { useMutation } from '@apollo/client';
import { UPDATE_JOB_OPPORTUNITY_ACK_EMAIL_THREAD_ID, UPDATE_JOB_OPPORTUNITY_FILTER_GMAIL_THREAD_IDS } from './queries';

const LightInfoChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.info.lighter,
  color: theme.palette.info.dark,
}));

const ReviewEmailsDialog = ({ open, onClose, jobData, refetchJobData }) => {
  const [ignoreDialogOpen, setIgnoreDialogOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [filteredEmails, setFilteredEmails] = useState([]);

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Initialize filteredEmails when jobData changes
  useEffect(() => {
    if (jobData && jobData.reviewEmails) {
      setFilteredEmails(jobData.reviewEmails.filter(email => email.from));
    }
  }, [jobData]);

  const [acknowledgeEmailThread] = useMutation(UPDATE_JOB_OPPORTUNITY_ACK_EMAIL_THREAD_ID, {
    onCompleted: (response) => {
      const updatedEmails = response.removeReviewEmailByThreadId.reviewEmails;
      setFilteredEmails(updatedEmails.filter(email => email.from));
      refetchJobData();
    },
    onError: (error) => {
      console.error('Error acknowledging email thread:', error);
    },
  });

  const [ignoreEmailThread] = useMutation(UPDATE_JOB_OPPORTUNITY_FILTER_GMAIL_THREAD_IDS, {
    onCompleted: (response) => {
      console.log('response', response);
      const updatedEmails = response.updateJobOpportunityAddFilterGmailThreadId.reviewEmails;
      setFilteredEmails(updatedEmails.filter(email => email.from));
      setIgnoreDialogOpen(false);
      setSelectedEmail(null);
      refetchJobData();
    },
    onError: (error) => {
      console.error('Error ignoring email thread:', error);
      setIgnoreDialogOpen(false);
    },
  });

  const handleIgnoreClick = (email) => {
    setSelectedEmail(email);
    setIgnoreDialogOpen(true);
  };

  const handleIgnoreDialogClose = () => {
    setIgnoreDialogOpen(false);
    setSelectedEmail(null);
  };

  const handleConfirmIgnore = () => {
    if (selectedEmail) {
      ignoreEmailThread({
        variables: {
          id: jobData.id,
          threadId: selectedEmail.threadId,
        },
      });
    }
  };

  const handleAcknowledgeClick = (email) => {
    acknowledgeEmailThread({
      variables: {
        id: jobData.id,
        threadId: email.threadId,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{
      '& .MuiDialog-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .MuiPaper-root': {
        overflow: 'visible', // Ensure overflow is visible
      },
    }}>
      <DialogTitle sx={{ pb: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <ModalHeader />
          <Typography variant="h6" sx={{ mt: 3 }}>Don't miss an important email...</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 1 }}>
          <CompanyAvatar website={jobData.website} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" component="div" fontWeight="bold">
              {jobData.company}
            </Typography>
            <Typography variant="body2" component="div">
              {jobData.positionTitle}
            </Typography>
          </Box>
        </Box>
        {filteredEmails.length > 0 ? (
          filteredEmails.map((email, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" flexDirection="column" width="100%">
                  {isMobile && email.from.length > 30 ? (
                    <Typography variant="subtitle1" sx={{ textOverflow: 'ellipsis' }}>{email.from.substring(0, 40)}...</Typography>
                  ) : (
                    <Typography variant="subtitle1">{email.from}</Typography>
                  )}

                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2" color="textSecondary">
                      {email.subject}
                    </Typography>
                    <LightInfoChip
                      label={formatDistanceToNow(new Date(email.date), { addSuffix: true })}
                      size="small"
                      sx={{ ml: 1, mr: 1 }}
                    />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {email.snippet}...
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Link
                    href={`https://mail.google.com/mail/u/0/#inbox/${email.threadId}`}
                    target="_blank"
                    rel="noopener"
                    variant="body2"
                    color="primary"
                  >
                    View in Gmail
                  </Link>
                  <Box>
                    <Tooltip title="Acknowledge This Thread">
                      <IconButton
                        size="small"
                        onClick={() => handleAcknowledgeClick(email)}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ignore This Thread">
                      <IconButton
                        size="small"
                        onClick={() => handleIgnoreClick(email)}
                      >
                        <BlockIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>You have no emails needing review.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <Dialog
        open={ignoreDialogOpen}
        onClose={handleIgnoreDialogClose}
      >
        <DialogTitle>Confirm Ignore Thread</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to ignore this thread? It will be added to the ignored list and will no longer provide updates when new emails arrive on this thread.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIgnoreDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmIgnore} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ReviewEmailsDialog;