import React, { useState, useEffect } from 'react';
import { Box, Avatar, CircularProgress, Typography } from '@mui/material';
import DocumentIcon from '@mui/icons-material/Description';
import { useTheme } from '@mui/system';

const ResumeImageDisplay = ({ fileKey, getPNGUrl, checkImageExists, resumeStatus }) => {
  const muiTheme = useTheme();
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    const checkImage = async () => {
      const pngUrl = getPNGUrl(fileKey);
      const exists = await checkImageExists(pngUrl);
      setImageExists(exists);
    };

    checkImage();
  }, [fileKey, getPNGUrl, checkImageExists]);

  const pngUrl = getPNGUrl(fileKey);

  return (
    <>
      {resumeStatus === 'Processing' || resumeStatus === 'New' ? (
        <Box sx={{ width: '100%', height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <CircularProgress />
          <Typography variant="caption" sx={{ mt: 1 }}>Processing</Typography>
        </Box>
      ) : imageExists ? (
        <Avatar src={pngUrl} alt="Resume Thumbnail" variant="square" sx={{ width: '100%', height: 150, mb: 2 }} />
      ) : (
        <Box sx={{ width: '100%', height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <DocumentIcon sx={{ fontSize: "75px", color: muiTheme.palette.primary.light }} />
        </Box>
      )}
    </>
  );
};

export default ResumeImageDisplay;