import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import stars from '../../components/assets/stars.jpg';


const darkTheme = createTheme({
  ...baseTheme,
  themeName: 'dark',
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    primary: {
      main: '#5f1fb1', // specific primary color for purple theme
    },
    secondary: {
      main: '#2572fd',
    },
    background: {
      default: '#111',
      
      paper: '#222', // Ensure paper background is also dark
    },
    text: {
      primary: '#fff',
      secondary: '#aaa',
      link: '#82b1ff', // Lighter color for links
    },
  },
  components: {
    ...baseTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            backgroundColor: '#111',
            backgroundImage: `url(${stars}) !important`,
            backgroundSize: 'cover !important',
            backgroundRepeat: 'no-repeat !important',
            backgroundPosition: 'center center !important',
            border: '2px solid blue !important',
            height: '100vh !important',
            margin: '0 !important',
            padding: '0 !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#222', // Ensure paper components are dark
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#222', // Dark background for AppBar
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          ...baseTheme.components.MuiDrawer?.styleOverrides?.root,
          '&.leftDrawer': {
            '& .MuiPaper-root': {
              // boxShadow: '2px 0 5px -0px #666',
              backgroundColor: '#222',
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...baseTheme.components.MuiListItem.styleOverrides.root,
          '&.leftDrawerListItem': {
            ...baseTheme.components.MuiListItem.styleOverrides.root['&.leftDrawerListItem'],
            // specific left drawer item styles for purple theme
            backgroundColor: 'transparent',
            color: '#fff',
            marginLeft: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius,
            marginBottom: 10,
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
          '&.rightDrawerItem': {
            ...baseTheme.components.MuiListItem.styleOverrides.root['&.rightDrawerItem'],
            // specific right drawer item styles for purple theme
            backgroundColor: '#f3e5f5',
          },
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Dark background for AppBar
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          ...baseTheme.components.MuiListItemIcon.styleOverrides.root,
          '&.leftDrawerListItemIcon': {
            ...baseTheme.components.MuiListItemIcon.styleOverrides.root['&.leftDrawerListItemIcon'],
            color: '#fff',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#82b1ff', // Set link color to lighter blue for better contrast
          '&:hover': {
            color: '#b3e5fc', // Lighten further on hover
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover': {
            color: '#82b1ff',
            '&:hover': {
              color: '#b3e5fc',
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff', // Ensure all icons are white in dark theme
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff', // Ensure button text is white for better contrast
          '&.MuiButton-textPrimary': {
            color: '#82b1ff', // Lighter blue for primary text buttons
            '&:hover': {
              backgroundColor: '#333', // Darker background on hover
              color: '#b3e5fc', // Lighter blue on hover
            },
          },
          '&.MuiButton-outlinedPrimary': {
            borderColor: '#82b1ff', // Lighter blue for primary outlined buttons
            color: '#82b1ff', // Lighter blue for text
            '&:hover': {
              backgroundColor: '#333', // Darker background on hover
              borderColor: '#b3e5fc', // Lighter blue border on hover
              color: '#b3e5fc', // Lighter blue text on hover
            },
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#82b1ff', // Lighter blue for primary contained buttons
            color: '#111', // Dark text for contrast
            '&:hover': {
              backgroundColor: '#b3e5fc', // Lighter blue on hover
            },
          },
        },
      },
    },
    // other component overrides specific to purple theme
  },
});

export default darkTheme;