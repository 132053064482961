import React, { useState, useEffect, useContext } from 'react';
import { Typography, useTheme, useMediaQuery, Divider, Tabs, Tab, Box, Backdrop, CircularProgress, Button, Chip } from '@mui/material';
import JobOpportunitiesTable from '../components/JobOpportunitiesTable';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import JobFormModal from '../components/JobFormModal';
import { AppContext } from '../AppContext'; // Update this line
import AddIcon from '@mui/icons-material/Add';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useRightDrawer } from '../RightDrawerContext';
import AlertWindow from '../components/AlertWindow'; // Update this line
import AddJobOpportunityButton from '../components/AddJobOpportunityButton';
import { GET_ALL_JOB_OPPORTUNITIES } from '../components/queries';


const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Box p={3} sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
        {children}
      </Box>
    )}
  </div>
);

const NoResults = ({ group, handleModalOpen }) => (
  <Box sx={{ textAlign: 'center', mt: 5 }}>
    <Typography variant="h5" gutterBottom>
      No {group} Jobs Found
    </Typography>
    <Typography variant="body1" gutterBottom>
      It looks like you haven't added any {group.toLowerCase()} jobs yet. Click the button below to create your first job opportunity.
    </Typography>
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={handleModalOpen}
      sx={{ mt: 3 }}
    >
      Add Your First Opportunity
    </Button>
    {navigator.userAgent.includes('Chrome') && (
      <Box sx={{ mt: 5 }}>
        <Typography variant="body1" gutterBottom>
          Tracking job opportunities is even easier with our Chrome extension.
        </Typography>
        <Button
          variant="outlined"
          startIcon={<ExtensionIcon />}
          href="#"
          sx={{ mt: 2 }}
        >
          Download Extension
        </Button>
      </Box>
    )}
  </Box>
);

const JobOpportunities = () => {
  const client = useApolloClient(); // Use Apollo client
  const { globalOnSave, setGlobalOnSave, loading, setLoading } = useContext(AppContext);
  const { openRightDrawer } = useRightDrawer();
  const [value, setValue] = useState(0);
  const [jobs, setJobs] = useState({ active: [], bookmarked: [], archived: [], toreview: []});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true); // New state for data loading
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const { data, loading: queryLoading, error, refetch } = useQuery(GET_ALL_JOB_OPPORTUNITIES);

  useEffect(() => {
    if (data) {
      const sortJobs = (jobs) => {
        return jobs.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      };

      setJobs({
        active: sortJobs(data.getJobOpportunities),
        bookmarked: sortJobs(data.bookmarked),
        archived: sortJobs(data.archived),
        toreview: sortJobs(data.toreview),
      });
    }
  }, [data]);

  useEffect(() => {
    setLoading(queryLoading);
  }, [queryLoading, setLoading]);

  useEffect(() => {
    if (!queryLoading) {
      const timer = setTimeout(() => setIsDataLoading(false), 100);
      return () => clearTimeout(timer);
    } else {
      setIsDataLoading(true);
    }
  }, [queryLoading]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const { data: newData } = await client.query({
          query: GET_ALL_JOB_OPPORTUNITIES,
          fetchPolicy: 'network-only',
        });

        if (newData) {
          const newCounts = {
            active: newData.getJobOpportunities.length,
            bookmarked: newData.bookmarked.length,
            archived: newData.archived.length,
            toReview: newData.toreview.length,
          };

          const currentCounts = {
            active: jobs.active.length,
            bookmarked: jobs.bookmarked.length,
            archived: jobs.archived.length,
            toReview: jobs.toreview.length,
          };

          if (
            newCounts.active !== currentCounts.active ||
            newCounts.bookmarked !== currentCounts.bookmarked ||
            newCounts.archived !== currentCounts.archived || 
            newCounts.toReview !== currentCounts.toReview
          ) {
            setJobs({
              active: newData.getJobOpportunities,
              bookmarked: newData.bookmarked,
              archived: newData.archived,
              toreview: newData.toReview,
            });
          }
        }
      } catch (error) {
        console.error('Error fetching job opportunities:', error);
      }
    }, 30000); // Check for updates every 30 seconds

    return () => clearInterval(intervalId);
  }, [client, jobs]);

  const handleChange = (_, newValue) => setValue(newValue);

  const handleChipClick = (event, jobId) => {
    setAnchorEl(event.currentTarget);
    setSelectedJobId(jobId);
  };

  const handleMenuClose = (newStatus) => {
    if (newStatus && selectedJobId !== null) {
      setJobs((prevJobs) =>
        prevJobs.map((job) => (job.id === selectedJobId ? { ...job, status: newStatus } : job))
      );
    }
    setAnchorEl(null);
    setSelectedJobId(null);
  };

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (jobId, group) => {
    const jobToEdit = jobs[group].find((job) => job.id === jobId);
    setEditingJob(jobToEdit);
    setIsModalOpen(true);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setEditingJob(null);
  };

  const handleModalClose = () => setIsModalOpen(false);

  useEffect(() => {
    const handleSave = (message) => {
      console.log('handleSave called with message:', message);
      refetch();
    };
    setGlobalOnSave(() => handleSave);
  }, [setGlobalOnSave, refetch]);

  
  const tabLabels = ['Active', 'Bookmarked', 'Archived', 'ToReview'];

  if (error) return <Typography>Error: {error.message}</Typography>;


  return (
    <>
      
      <Box sx={{ width: '100%'}}>
      <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '66px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="job opportunities tabs" variant="scrollable" scrollButtons='auto'
 allowScrollButtonsMobile>
          {tabLabels.map((label, index) => (
            <Tab
              key={label}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {(label == "ToReview" ? "To Review" : label)}
                  <Chip label={jobs[label.toLowerCase()].length} size="small" sx={{ ml: 1 }} />
                </Box>
              }
            />
          ))}
        </Tabs>
        {!isMobile && (
        <AlertWindow sx={{ mr: '10px' }} />
        )}
      </Box>
      {!isMobile && (
      <Box sx={{ position: 'relative' }}>
        <Divider sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          width: '100%',
          transform: 'translateX(-50%)',
          marginLeft: '50%'
        }} />
      </Box>
      )}
      {/* <JobFormModal open={isModalOpen} handleClose={handleModalClose} job={editingJob} onSave={globalOnSave} /> */}
    </div>
        <Box sx={{ mt: 2 }} >
          {tabLabels.map((label, index) => (
            <TabPanel value={value} index={index} key={label}>
              {isDataLoading ? null : jobs[label.toLowerCase()].length > 0 ? (
                <JobOpportunitiesTable
                  jobs={jobs[label.toLowerCase()]}
                  label={label.toLowerCase()}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChipClick={handleChipClick}
                  handleMenuClose={handleMenuClose}
                  handleEditClick={(jobId) => handleEditClick(jobId, label.toLowerCase())} // Pass group to handleEditClick
                  anchorEl={anchorEl}
                  selectedJobId={selectedJobId}
                  emptyRows={rowsPerPage - Math.min(rowsPerPage, jobs[label.toLowerCase()].length - page * rowsPerPage)}
                  onSave={globalOnSave}
                  refetch={refetch}
                  sx={{ pt: 0 }}
                />
              ) : (
                <NoResults group={label} handleModalOpen={handleModalOpen} />
              )}
            </TabPanel>
          ))}
        </Box>
      </Box>
      {/* <AddJobOpportunityButton /> */}
      <JobFormModal open={isModalOpen} handleClose={handleModalClose} job={editingJob} onSave={globalOnSave} />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={queryLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default JobOpportunities;