import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { getCurrentUser } from './auth'; // Adjust the path to your auth file
import { GET_USER } from './components/queries'; // Adjust the path to your queries file

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const client = useApolloClient();
  const [globalOnSave, setGlobalOnSave] = useState(() => () => {});
  const [loading, setLoading] = useState(false); // General loading state
  const [userLoading, setUserLoading] = useState(true); // User-related loading state
  const [user, setUser] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: GET_USER,
        fetchPolicy: 'network-only', // Ensure we get the latest data from the server
      });
      return data.getUser;
    } catch (error) {
      console.error('Error fetching user data', error);
      return null;
    }
  }, [client]);

  const fetchUser = useCallback(async () => {
    const currentUser = getCurrentUser();
    console.log("AppContext.js fetchUser currentUser: ", currentUser);
    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (!err) {
          const userData = session.getIdToken().decodePayload();
          const fetchedData = await fetchUserData();
          setUser({
            ...userData,
            ...fetchedData
          });
        }
        setUserLoading(false); // Set userLoading to false once data is fetched
        console.log("User loading state changed to false in fetchUser");
      });
    } else {
      setUserLoading(false); // Set userLoading to false if no current user
      console.log("User loading state changed to false - no current user");
    }
  }, [fetchUserData]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    console.log("User loading state changed: ", userLoading);
  }, [userLoading]);

  const refetchUser = useCallback(async () => {
    setUserLoading(true);
    await fetchUser();
    setUserLoading(false);
  }, [fetchUser]);

  const logout = () => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
      setUser(null);
      window.location.href = '/signin';
    }
  };

  const contextValue = useMemo(() => ({
    globalOnSave,
    setGlobalOnSave,
    loading,
    setLoading,
    userLoading,
    setUserLoading,
    user,
    setUser,
    refetchUser, // Add refetchUser to the context value
    logout
  }), [globalOnSave, loading, userLoading, user, fetchUser, refetchUser]);

  console.log("AppContext.js Returned");

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};