// src/components/PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../config';

const poolData = {
  UserPoolId: config.cognito.USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);

const isAuthenticated = () => {
  const user = userPool.getCurrentUser();
  return user != null;
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
