import React, { useRef, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { STATUS_OPTIONS } from '../../config';

const JobForm = ({ formData, handleChange, handleSaveClick, handleCancelClick }) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (!formData.processStatus) {
      handleChange({ target: { name: 'processStatus', value: STATUS_OPTIONS[0] } });
    }
    if (!formData.workingLocation) {
      handleChange({ target: { name: 'workingLocation', value: 'Not Mentioned' } });
    }
    if (!formData.employmentType) {
      handleChange({ target: { name: 'employmentType', value: 'Full-Time' } });
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    if (formRef.current.reportValidity()) {
      handleSaveClick();
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off" ref={formRef} onSubmit={onSubmit}>
      <TextField
        fullWidth
        required
        label="Company"
        name="company"
        value={formData.company}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Company Overview"
        name="companyOverview"
        value={formData.companyOverview}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Position Title"
        name="positionTitle"
        value={formData.positionTitle}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Position Overview"
        name="positionOverview"
        value={formData.positionOverview}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Location"
        name="companyLocation"
        value={formData.companyLocation}
        onChange={handleChange}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Working Location</InputLabel>
        <Select
          name="workingLocation"
          value={formData.workingLocation || 'Not Mentioned'}
          onChange={handleChange}
          required
        >
          <MenuItem value="On-site">On-site</MenuItem>
          <MenuItem value="Hybrid">Hybrid</MenuItem>
          <MenuItem value="Remote">Remote</MenuItem>
          <MenuItem value="Not Mentioned">Not Mentioned</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Employment Type</InputLabel>
        <Select
          name="employmentType"
          value={formData.employmentType || 'Full-Time'}
          onChange={handleChange}
          required
        >
          <MenuItem value="Full-Time">Full-Time</MenuItem>
          <MenuItem value="Part-Time">Part-Time</MenuItem>
          <MenuItem value="Contract">Contract</MenuItem>
          <MenuItem value="Not Mentioned">Not Mentioned</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Benefits"
        name="benefits"
        value={formData.benefits}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Responsibilities"
        name="responsibilities"
        value={formData.responsibilities}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Qualifications"
        name="qualifications"
        value={formData.qualifications}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Travel Requirements"
        name="travelRequirements"
        value={formData.travelRequirements}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Website"
        name="website"
        value={formData.website}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Salary"
        name="salary"
        value={formData.salary}
        onChange={handleChange}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select
          name="processStatus"
          value={formData.processStatus || STATUS_OPTIONS[0]} // Default to the first option if not set
          onChange={handleChange}
          required
        >
          {STATUS_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        required
        label="Date Applied"
        name="dateApplied"
        type="date"
        value={formData.dateApplied}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="Posting URL"
        name="postingUrl"
        value={formData.postingUrl}
        onChange={handleChange}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Group</InputLabel>
        <Select
          name="group"
          value={formData.group || 'active'} // Default to 'active' if not set
          onChange={handleChange}
          required
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="bookmarked">Bookmarked</MenuItem>
          <MenuItem value="archived">Archived</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, width: '100%', alignItems: 'center' }}>
        <Button type="submit" variant="contained" color="primary" sx={{ width: '100%', maxWidth: 300 }}>Save</Button>
        <Button variant="contained" color="error" onClick={handleCancelClick} sx={{ width: '100%', maxWidth: 300 }}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default JobForm;