import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery, Typography, Box, Button, Divider, CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_RESUME, GET_RESUMES, GET_PRE_SIGNED_URL, DELETE_RESUME } from '../components/queries';
import AddIcon from '@mui/icons-material/Add';
import ResumeCard from '../components/ResumeCard'; // Import the new ResumeCard component

const Input = styled('input')({
  display: 'none',
});

const getPNGUrl = (pdfKey) => {
  return `https://joboomi-resumes-bucket-dev.s3.us-east-1.amazonaws.com/${pdfKey.replace('.pdf', '.png')}`;
};

const checkImageExists = (imageUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

const Portfolio = () => {
  const [files, setFiles] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const [getPreSignedUrl] = useLazyQuery(GET_PRE_SIGNED_URL);
  const [createResume] = useMutation(CREATE_RESUME);
  const [deleteResume] = useMutation(DELETE_RESUME);
  const { loading, error, data, refetch } = useQuery(GET_RESUMES);

  useEffect(() => {
    if (data && data.getResumes) {
      setFiles(data.getResumes);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => clearInterval(interval);
  }, [refetch]);

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setUploading(true);

    for (const file of selectedFiles) {
      try {
        const { data } = await getPreSignedUrl({ variables: { title: file.name } });
        if (!data || !data.getPreSignedUrl) {
          throw new Error('Failed to get pre-signed URL');
        }

        const { uploadURL, s3Key } = data.getPreSignedUrl;

        const response = await fetch(uploadURL, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to upload file to S3');
        }

        await createResume({
          variables: {
            input: {
              title: file.name,
              description: '',
              s3Key: s3Key
            },
          },
        });

        setTimeout(() => {
          refetch();
          setUploading(false);
        }, 2000);
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploading(false);
      }
    }
  };

  const handleMenuClick = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedFile(null);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    setFileToDelete(selectedFile);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteResume({ variables: { id: fileToDelete.id } });
      setFiles(files.filter(file => file.id !== fileToDelete.id));
      setDeleteDialogOpen(false);
      setFileToDelete(null);
    } catch (error) {
      console.error('Error deleting resume:', error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setFileToDelete(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return <p>Error loading resumes.</p>;

  return (
    <>
      <Box>
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '66px' }}>
            <Typography variant="h6">
                <>Portfolio</>
            </Typography>
            <>
              <label htmlFor="upload-button">
                <Input
                  accept=".pdf,.doc,.docx"
                  id="upload-button"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                <Button variant="contained" component="span" disabled={uploading} size="small" startIcon={<AddIcon />}>
                  {uploading ? <CircularProgress size={24} /> : 'Upload Resume'}
                </Button>
              </label>
            </>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Divider sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              transform: 'translateX(-50%)',
              marginLeft: '50%'
            }} />
          </Box>
        </div>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {files.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3} sx={{ p: 0, m: 0 }}>
              <ResumeCard
                file={file}
                getPNGUrl={getPNGUrl}
                checkImageExists={checkImageExists}
                onMenuClick={handleMenuClick}
                onDeleteClick={handleDeleteClick}
                anchorEl={anchorEl}
                selectedFile={selectedFile}
                handleMenuClose={handleMenuClose}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this resume?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Portfolio;