import React, { useState } from 'react';
import { Accordion, Stack, Box, Chip, Divider, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Typography, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import styled from 'styled-components';

const AccordionDetailsStyled = styled(AccordionDetails)`
  max-height: 200px; // Set a maximum height for accordion details
  overflow-y: auto; // Add vertical scroll if content overflows
`;


function CircularProgressWithLabel({ value, size, ...otherProps }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex'}}>
      <CircularProgress 
        variant="determinate"
        value={value}
        sx={{
          width: size,
          height: size,
        }}
        {...otherProps} // This spreads any additional props to the CircularProgress
        />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const FeedbackSections = ({ feedback }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    feedback && (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h6">Feedback</Typography>
        </Divider>
        {feedback.experienceMatch === 'Yes' ? (
  <Alert severity="success" sx={{ mb: 2 }}>You are a good match for this role.  </Alert>
) : (
  <Alert severity="warning" sx={{ mb: 2 }}>You are likely not a great match for this role. </Alert>
)}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
  <Typography variant="body2" sx={{ flexGrow: 1, mr: 2  }}>
    {feedback.experienceFeedback}
  </Typography>
  <Box sx={{ flexShrink: 0, width: 80 }}>
    <CircularProgressWithLabel value={feedback.score} size='140px' />
  </Box>
</Box>

        <Accordion expanded={expanded === 'matchKeywords'} onChange={handleChange('matchKeywords')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip sx={{ mr: 2, width: '40px;', p: 0 }} label={feedback.matchKeywords.length} /> Likely ATS Matches
            </Typography>
          </AccordionSummary>
          <AccordionDetailsStyled>
          <Alert severity="success" variant="outlined" sx={{ mb: 1 }}>The following words or phrases are likely to be in an ATS screen and should pass your resume.</Alert>
          <Typography variant="body2" gutterBottom>{feedback.matchKeywords.join(", ")}</Typography>
          </AccordionDetailsStyled>
        </Accordion>
        <Accordion expanded={expanded === 'suggestedKeywords'} onChange={handleChange('suggestedKeywords')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip sx={{ mr: 2, width: '40px;', p: 0 }} label={feedback.suggestedKeywords.length} /> Suggested Keywords to Add
            </Typography>
          </AccordionSummary>
          <AccordionDetailsStyled>
          <Alert severity="info" variant="outlined" sx={{ mb: 1 }}>You may want to consider adding some of the following words or phrases to your resume.</Alert>

            <Typography variant="body2" gutterBottom>{feedback.suggestedKeywords.join(", ")}</Typography>
            {/* <List dense>
              {feedback.suggestedKeywords.map((item, index) => (
                <ListItem key={index} sx={{ py: 0.1 }}>
                  <ListItemText  primary={item} />
                </ListItem>
              ))}
            </List> */}
          </AccordionDetailsStyled>
        </Accordion>

        <Accordion expanded={expanded === 'goodThings'} onChange={handleChange('goodThings')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon sx={{ mr: 2, color: 'green', width: '40px', fontSize: '40px' }} /> Good Things
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pt: 0 }}>
            <List dense  sx={{pt: 0 }}>
              {feedback.goodThings.map((item, index) => (
                <ListItem key={index} sx={{ py: 0.1 }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'notSoGoodThings'} onChange={handleChange('notSoGoodThings')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon sx={{ mr: 2, color: '#a67431', width: '40px', fontSize: '40px'}} /> Areas to Improve
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pt: 0 }}>
            <List dense sx={{pt: 0 }}>
              {feedback.notSoGoodThings.map((item, index) => (
                <ListItem key={index} sx={{ py: 0.1 }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'recommendations'} onChange={handleChange('recommendations')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center' }}>
              <TipsAndUpdatesIcon sx={{ mr: 2, color: '#62a5fb',  width: '40px', fontSize: '40px' }} /> Recommendations
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pt: 0 }}>
            <List dense sx={{pt: 0 }}>
              {feedback.recommendations.map((item, index) => (
                <ListItem key={index} sx={{ py: 0.1 }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </>
    )
  );
};

export default FeedbackSections;