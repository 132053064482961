import React, { useEffect, useState, useContext } from 'react';
import { useMediaQuery, useTheme, Divider, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, TextField, TablePagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteIcon from '@mui/icons-material/Note';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useQuery, useMutation } from '@apollo/client';
import SnackbarComponent from '../components/SnackbarComponent';
import ContactFormModal from '../components/ContactFormModal';
import { AppContext } from '../AppContext'; // Update this line
import { GET_CONTACTS, DELETE_CONTACT } from '../components/queries';
import { useRightDrawer } from '../RightDrawerContext';

const Contacts = () => {
  const { globalOnSave, setGlobalOnSave } = useContext(AppContext);
  const { data, loading, error, refetch } = useQuery(GET_CONTACTS);
  const [deleteContact] = useMutation(DELETE_CONTACT, {
    onCompleted: () => {
      console.log('Contact deleted successfully');
      setSnackbarMessage('Contact deleted successfully!');
      setSnackbarOpen(true);
      refetch();
    },
    onError: (error) => {
      console.error('Error deleting contact:', error);
    },
  });
  const [contacts, setContacts] = useState([]);
  const [editingContact, setEditingContact] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { openRightDrawer } = useRightDrawer();

  useEffect(() => {
    if (data) {
      setContacts(data.getContacts);
    }
  }, [data]);

  useEffect(() => {
    const handleSave = (message) => {
      console.log('handleSave called with message:', message);
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      refetch(); // Refetch contacts to update the table
    };

    setGlobalOnSave(() => handleSave);
  }, [setGlobalOnSave, refetch]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  const handleEditClick = (contact) => {
    setEditingContact(contact);
    setIsModalOpen(true);
    handleCloseMenu();
  };

  const handleDeleteClick = (contact) => {
    setSelectedContact(contact);
    setIsDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleConfirmDelete = () => {
    console.log('Confirm delete:', selectedContact);
    if (selectedContact) {
      deleteContact({ variables: { id: selectedContact.id } });
      setIsDeleteDialogOpen(false);
      setSelectedContact(null);
    }
  };

  const handleMenuClick = (event, contact) => {
    setAnchorEl(event.currentTarget);
    setSelectedContact(contact);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedContact(null);
  };

  const handleModalClose = () => {
    setEditingContact(null);
    setIsModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddContactClick = () => {
    setEditingContact(null);
    setIsModalOpen(true);
  };

  const handleNotesClick = (contact) => {
    setCurrentNotes(contact.notes || []);
    setSelectedContact(contact);
    setIsNotesModalOpen(true);
  };

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false);
    setNewNote('');
  };

  const handleAddNote = () => {
    const newNotes = [
      ...currentNotes,
      { createdAt: new Date().toISOString(), noteContent: newNote },
    ];
    setCurrentNotes(newNotes);
    setNewNote('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (contact) => {
    console.log("CONTACT", contact);
    openRightDrawer('contact', contact, refetch);
  };
  return (
    <>
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '66px' }}>
          <Typography variant='h6'>Contacts</Typography>
        
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddContactClick}
            sx={{ ml: 2 }}
            size="small"
            startIcon={<AddIcon />}
          >
            New Contact
          </Button>
        </Box>
        {!isMobile && (
          <Box sx={{ position: 'relative' }}>
            <Divider sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              transform: 'translateX(-50%)',
              marginLeft: '50%',
            }} />
          </Box>
        )}
      </div>
      {contacts.length === 0 ? (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h5" gutterBottom>
            No Contacts Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            It looks like you haven't added any contacts yet. Click the button below to create your first contact.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddContactClick}
            sx={{ mt: 3 }}
          >
            Create Your First Contact
          </Button>
        </Box>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden', mt: (!isMobile ? 2 : 0) }}>
          <TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={contacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Divider />
            <Table>

              <TableBody>
                {contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contact) => (
                  <TableRow key={contact.id} hover onClick={() => handleRowClick(contact)}>
                    <TableCell sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ mr: 2 }} src={contact.linkedinPhotoUrl || ''}>
                          {!contact.linkedinPhotoUrl && `${contact.firstName[0]}${contact.lastName[0]}`}
                        </Avatar>
                        <Box>
                          <Typography variant="body1" fontWeight="bold">{contact.firstName} {contact.lastName}</Typography>
                          <Typography variant="body2" color="textSecondary">{contact.company}</Typography>
                          <Typography variant="body2" color="textSecondary">{contact.title}</Typography>
                          {isMobile && (
                            <>
                              {contact.email && ( 
                                <Typography variant="body2" color="textSecondary">
                                  Email: {contact.email}
                                </Typography>
                              )}
                              {contact.phone && ( 
                                <Typography variant="body2" color="textSecondary">
                                  Phone: {contact.phone}
                                </Typography>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isMobile && (
                      <TableCell sx={{ width: '350px' }}>
                        <Box>
                          {contact.email && ( 
                            <Typography variant="body2" color="textSecondary">
                              Email: {contact.email}
                            </Typography>
                          )}
                          {contact.phone && ( 
                            <Typography variant="body2" color="textSecondary">
                              Phone: {contact.phone}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}
                    <TableCell sx={{ width: '80px' }}>
                      {contact.linkedinUrl && (
                       <IconButton href={contact.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon />
                       </IconButton> 
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={contacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {isModalOpen && (
        <ContactFormModal
          open={isModalOpen}
          handleClose={handleModalClose}
          contact={editingContact}
          onSave={globalOnSave}
        />
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isNotesModalOpen}
        onClose={handleNotesModalClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Notes</DialogTitle>
        <DialogContent dividers>
          {currentNotes.map((note, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body2" color="textSecondary">{new Date(note.createdAt).toLocaleString()}</Typography>
              <Typography variant="body1">{note.noteContent}</Typography>
            </Box>
          ))}
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label="Add Note"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAddNote}
            >
              Add Note
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotesModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        link="/contacts"
      />
    </>
  );
};

export default Contacts;