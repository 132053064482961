import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, CircularProgress, Box, IconButton, Switch, FormControlLabel } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import NoteEditor from './utilities/noteEditor';
import { GET_JOB_OPPORTUNITY_BY_ID, UPDATE_PERSONAL_NOTE } from '../components/queries';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ModalHeader from './ModalHeader';
import { format } from 'date-fns';

const JobNoteModal = ({ open, onClose, jobId, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState('');
  const [originalNote, setOriginalNote] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [job, setJob] = useState(null);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState(true);
  const [isAutoSaveTriggered, setIsAutoSaveTriggered] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const autoSaveInterval = useRef(null);

  const { data, loading, error, refetch } = useQuery(GET_JOB_OPPORTUNITY_BY_ID, {
    variables: { id: jobId },
    skip: !open, // Skip the query until the modal is open
  });

  const [updatePersonalNote] = useMutation(UPDATE_PERSONAL_NOTE, {
    onCompleted: (response) => {
      refetch();
      onSave(note);
      setOriginalNote(note);
      setTimeout(() => {
        setIsAutoSaving(false);
      }, 2000);
      if (isAutoSaveTriggered) {
        setIsEditing(true);
      }
    },
    onError: (error) => {
      console.error('Error updating note:', error);
      setIsAutoSaving(false);
    },
  });

  const [updatePersonalNoteAutoSave] = useMutation(UPDATE_PERSONAL_NOTE, {
    onCompleted: (response) => {
      // refetch();
      // onSave(note);
      setOriginalNote(note);
      setTimeout(() => {
        setIsAutoSaving(false);
      }, 2000);
      setIsEditing(true);
    },
    onError: (error) => {
      console.error('Error updating note:', error);
      setIsAutoSaving(false);
    },
  });

  useEffect(() => {
    if (data && data.getJobOpportunityById) {
      const fetchedJob = data.getJobOpportunityById;
      setJob(fetchedJob);
      setNote(fetchedJob.personalNote || '');
      setOriginalNote(fetchedJob.personalNote || '');
      setIsEditing(!fetchedJob.personalNote);
    }
  }, [data]);

  useEffect(() => {
    if (isEditing && isAutoSaveEnabled) {
      autoSaveInterval.current = setInterval(() => {
        if (note !== originalNote) {
          setIsAutoSaving(true);
          setIsAutoSaveTriggered(true);
          console.log("Auto saving note...", isAutoSaveEnabled);
          console.log("Auto saving note triggered.", isAutoSaveTriggered);
          updatePersonalNoteAutoSave({ variables: { id: jobId, personalNote: note } });
        }
      }, 5000); // Autosave every 15 seconds

      return () => clearInterval(autoSaveInterval.current);
    }
  }, [isEditing, note, originalNote, updatePersonalNote, updatePersonalNoteAutoSave, jobId, isAutoSaveEnabled, isAutoSaveTriggered]);

  const handleNoteChange = (content, delta, source, editor) => {
    setNote(editor.getHTML());
  };

  const handleSaveNote = () => {
    setIsAutoSaving(true);
    setIsAutoSaveTriggered(true);
    updatePersonalNote({ variables: { id: jobId, personalNote: note } });
  };

  const handleSaveNoteClick = () => {
    setIsAutoSaving(false);
    setIsAutoSaveTriggered(false);
    console.log("Save Clicked");
    updatePersonalNote({ variables: { id: jobId, personalNote: note } });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    if (note !== originalNote) {
      setShowConfirmDialog(true);
    } else {
      setIsEditing(false);
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    setIsEditing(false);
    setNote(originalNote);
  };

  const handleDismissConfirm = () => {
    setShowConfirmDialog(false);
  };

  const handleClose = () => {
    if (isEditing && note !== originalNote) {
      setShowConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleAutoSave = () => {
    setIsAutoSaveEnabled(!isAutoSaveEnabled);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography variant="body1">Error loading job opportunity</Typography>;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={isSmallScreen}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiPaper-root': {
            width: isSmallScreen ? '90%' : isFullScreen ? '90%' : '50%',
            height: isSmallScreen ? '95%' : isFullScreen ? '90%' : '50%',
            margin: isSmallScreen ? 0 : 'auto',
            overflow: 'visible', // Ensure overflow is visible
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <ModalHeader />
            <Typography variant="h6" sx={{ mt: 3 }}>Notes on {job?.company}</Typography>
            {!isSmallScreen && (
            <IconButton onClick={toggleFullScreen} sx={{ position: 'absolute', right: 8, top: 8 }}>
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: 'calc(100% - 64px)', overflow: 'scroll' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {job?.personalNoteUpdatedAt && (
              <Typography variant="body2" color="textSecondary">
                Last updated: {format(new Date(job.personalNoteUpdatedAt), 'MMMM d, yyyy HH:mm')}
              </Typography>
            )}
            {isEditing && (
              isAutoSaving ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={14} sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Auto saving...
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {isAutoSaveEnabled ? 'Autosave On' : 'Autosave Off'}
                </Typography>
              )
            )}
          </Box>
          {isEditing ? (
            <NoteEditor value={note} onChange={handleNoteChange} />
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: note }} sx={{ overflowY: 'scroll', height: '90%' }} />
          )}
        </DialogContent>
        <DialogActions>
          {isEditing && (
            <FormControlLabel
              control={<Switch checked={isAutoSaveEnabled} onChange={toggleAutoSave} color="primary" />}
              label="Autosave"
            />
          )}
          {isEditing ? (
            <>
              <Button onClick={handleCancelEdit} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveNoteClick} color="primary">
                Save
              </Button>
            </>
          ) : (
            <Button onClick={handleEditClick} color="primary">
              Edit
            </Button>
          )}
          {!isEditing && (
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmDialog} onClose={handleDismissConfirm}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Your changes have not been saved. Continue?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismissConfirm} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmClose} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobNoteModal;