import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel, MenuItem, Divider, Typography } from '@mui/material';

const ResumeSelector = ({ resumes, selectedResume, handleResumeChange, loading, selectedRole, handleRoleChange }) => {
  const [interviewRoles, setInterviewRoles] = useState([]);

  useEffect(() => {
    if (selectedResume) {
      const resume = resumes.find(r => r.id === selectedResume);
      if (resume && resume.interviewRoles) {
        setInterviewRoles(resume.interviewRoles);
      } else {
        setInterviewRoles([]);
      }
    } else {
      setInterviewRoles([]);
    }
  }, [selectedResume, resumes]);

  return (
    <div>
      <FormControl fullWidth margin="normal" sx={{ mt: 3 }}>
        <InputLabel id="resume-select-label" sx={{
          backgroundColor: '#fff',
          padding: '0 4px',
        }}>Select Resume</InputLabel>
        <Select
          labelId="resume-select-label"
          value={selectedResume}
          onChange={handleResumeChange}
          disabled={loading}
        >
          {resumes.filter(resume => resume.resumeStatus === "Ready").map((resume) => (
            <MenuItem key={resume.id} value={resume.id}>
              {resume.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {selectedResume && (
        <>
          <FormControl fullWidth margin="normal" sx={{ mb: 3 }}>
            <InputLabel id="role-select-label" sx={{
          backgroundColor: '#fff',
          padding: '0 4px',
        }}>Interview Prep With</InputLabel>
            <Select
              labelId="role-select-label"
              value={selectedRole}
              onChange={handleRoleChange}
              disabled={loading}
            >
              {interviewRoles.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default ResumeSelector;