import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, IconButton, InputAdornment, CircularProgress, Typography, Button, Link, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useLazyQuery } from '@apollo/client';
import { EXTRACT_CONTENT, PROCESS_JOB_INFO } from './queries'; // Import queries
import { useRightDrawer } from '../RightDrawerContext'; // Import RightDrawer context
import { useSnackbar } from '../SnackbarContext'; // Import Snackbar context
import ModalHeader from './ModalHeader';

const JobFormModal = ({ open, handleClose, job, refetch }) => {
  const { openRightDrawer } = useRightDrawer(); // Use the drawer context
  const [postingUrl, setPostingUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [formData, setFormData] = useState({
    company: '',
    positionTitle: '',
    salary: '',
    dateApplied: new Date().toISOString().split('T')[0], // Default to today
    processStatus: 'Applied', // Default to Applied
    group: 'active', // Default to Active
    postingUrl: ''
  });

  const { showSnackbar } = useSnackbar();
  const [tipsOpen, setTipsOpen] = useState(false);

  const processQualifications = (qualifications) => {
    try {
      const parsedQualifications = JSON.parse(qualifications);
      if (Array.isArray(parsedQualifications)) {
        return parsedQualifications.join('\n');
      }
    } catch (e) {
      // If JSON parsing fails, do nothing
    }
    return qualifications;
  };

  const [extractContent, { data: extractData, loading: extractLoading, error: extractError }] = useLazyQuery(EXTRACT_CONTENT, {
    onCompleted: (data) => {
      if (data && data.extractContent) {
        setLoadingMessage('Extracting relevant information...');
        processJobInfo({
          variables: {
            textContent: data.extractContent.textContent,
            save: false,
            url: postingUrl,
          }
        }).finally(() => {
          setLoading(false);
          setLoadingMessage('');
        });
      } else {
        setSearchResult('failure');
      }
    },
    onError: () => {
      setSearchResult('failure');
      setLoading(false);
      setLoadingMessage('');
    }
  });

  const [processJobInfo, { data: processData, loading: processLoading, error: processError }] = useLazyQuery(PROCESS_JOB_INFO, {
    onCompleted: (data) => {
      if (data && data.processJobInfo) {
        console.log("Testing Qualifiactions", data.processJobInfo);
        const processJobInfo = data.processJobInfo;
        //processJobInfo.qualifications = processQualifications(processJobInfo.qualifications);
        //console.log("Qualifiacations", data.processJobInfo.qualifications);
        setSearchResult('success');
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...processJobInfo,
          dateApplied: new Date().toISOString().split('T')[0], // Default to today
          processStatus: 'Applied', // Default to Applied
          group: 'active' // Default to Active
        }));

        // Open right drawer with the pre-filled form data
        openRightDrawer('jobOpportunity', {
          ...processJobInfo,
          dateApplied: new Date().toISOString().split('T')[0],
          processStatus: 'Applied',
          group: 'active'
        });

        handleClose();
      } else {
        setSearchResult('failure');
      }
    },
    onError: () => {
      setSearchResult('failure');
    }
  });

  useEffect(() => {
    if (job) {
      setFormData(job);
    } else {
      setFormData({
        company: '',
        positionTitle: '',
        salary: '',
        dateApplied: new Date().toISOString().split('T')[0], // Default to today
        processStatus: 'Applied', // Default to Applied
        group: 'active', // Default to Active
        postingUrl: ''
      });
      setPostingUrl('');
      setSearchResult(null);
    }
  }, [job, open]);

  const validateUrl = (url) => {
    const urlPattern = /^(https?:\/\/)[^\s]+$/;
    return urlPattern.test(url);
  };

  const handleUrlChange = (e) => {
    setPostingUrl(e.target.value);
  };

  const handleSearchClick = () => {
    if (!validateUrl(postingUrl)) {
      setSearchResult('failure');
      return;
    }
    setLoading(true);
    setLoadingMessage('Opening job URL...');
    setSearchResult(null);
    extractContent({ variables: { url: postingUrl } });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleTipsOpen = () => {
    setTipsOpen(true);
  };

  const handleTipsClose = () => {
    setTipsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiPaper-root': {
            width: '90%',
            height: 'auto',
            margin: 'auto',
            pb: 2,
            overflow: 'visible', // Ensure overflow is visible
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <ModalHeader />
            <Typography variant="h6" sx={{ mt: 3 }}>Add a New Job Opportunity</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: 'auto', overflow: 'visible' }}>
          <TextField
            fullWidth
            label="Job Posting URL"
            value={postingUrl}
            onChange={handleUrlChange}
            onKeyDown={handleKeyDown}
            margin="normal"
            error={searchResult === 'failure'}
            helperText={loadingMessage || (searchResult === 'failure' ? "URL doesn't appear to have appropriate job information" : '')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClick} disabled={loading || extractLoading || processLoading}>
                    {(loading || extractLoading || processLoading) ? <CircularProgress size={24} /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              style: (searchResult === 'failure' || loadingMessage) ? { borderColor: 'darkblue' } : {}
            }}
            FormHelperTextProps={{
              style: { color: (searchResult === 'failure' || loadingMessage) ? 'darkblue' : '' }
            }}
          />
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Enter the URL in the above box and click the search icon to use our AI Assisted Extraction. <Link href="#" onClick={handleTipsOpen}>Helpful Tips</Link>
          </Typography>
          <Divider sx={{ mt: 2 }}><Typography variant="h6">OR</Typography></Divider>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => {
              openRightDrawer('addOpportunity', formData, refetch);
              handleClose();
            }}
          >
            Enter my own Job Information
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={tipsOpen}
        onClose={handleTipsClose}
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiPaper-root': {
            width: '90%',
            height: 'auto',
            margin: 'auto',
            overflow: 'visible', // Ensure overflow is visible
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TipsAndUpdatesIcon sx={{ mr: 1 }} />
            <Typography variant="h5">Tips for AI Assisted Job Data Extraction</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <strong>LinkedIn Jobs:</strong> Copy the URL from the address bar of the LinkedIn job posting and paste it in the URL field.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Indeed Jobs:</strong> Copy the URL from the address bar of the Indeed job posting and paste it in the URL field.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Other Sites:</strong> Copy the URL from the address bar of the job posting and paste it in the URL field.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTipsClose}
            sx={{ mt: 2 }}
          >
            Close Tips
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default JobFormModal;