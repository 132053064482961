import React from 'react';
import { FormControl, Select, InputLabel, MenuItem, Divider, Typography } from '@mui/material';

const ResumeSelector = ({ resumes, selectedResume, handleResumeChange, loading }) => (
  <div>
    <FormControl fullWidth margin="normal" sx={{ mt: 3 }}>
      <InputLabel id="resume-select-label" sx={{
          backgroundColor: '#fff',
          padding: '0 4px',
        }}>Select Resume</InputLabel>
      <Select
        labelId="resume-select-label"
        value={selectedResume}
        onChange={handleResumeChange}
        disabled={loading}
      >
        {resumes.filter(resume => resume.resumeStatus === "Ready").map((resume) => (
          <MenuItem key={resume.id} value={resume.id}>
            {resume.title} {/* Ensure this matches the actual field name for resume titles */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Divider sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h6">Assist Me With</Typography>
    </Divider>
  </div>
);

export default ResumeSelector;