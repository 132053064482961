import React from 'react';
import DrawerListItem from './DrawerListItem';
import { Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ResourcesIcon from '@mui/icons-material/LibraryBooks';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WorkIcon from '@mui/icons-material/Work';


const DrawerContent = ({ drawerOpen }) => {
  return (
    <Box>
      <DrawerListItem
        icon={<WhatshotIcon sx={{ width: '22px; '}} />}
        text="Job Opportunities"
        to="/job-opportunities"
        miniDrawer={!drawerOpen} 
      />
      <DrawerListItem icon={<FolderIcon sx={{ width: '22px; '}}/>} text="Portfolio" to="/portfolio"  miniDrawer={!drawerOpen} />
      {/* <DrawerListItem icon={<ResourcesIcon />} text="Resources" to="/resources"  miniDrawer={!drawerOpen} /> */}
      <DrawerListItem icon={<ContactsIcon sx={{ width: '22px; '}} />} text="Contacts" to="/contacts"  miniDrawer={!drawerOpen} />
      <DrawerListItem icon={<CalendarTodayIcon sx={{ width: '22px; '}} />} text="Calendar" to="/calendar"  miniDrawer={!drawerOpen} />
    </Box>
  );
};

export default DrawerContent;