import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { getUserSub } from '../auth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      firstName
      lastName
      email
      phone
      notes
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      firstName
      lastName
      email
      phone
      notes
    }
  }
`;

const removeTypename = (key, value) => (key === '__typename' ? undefined : value);

const ContactFormModal = ({ open, handleClose, contact, onSave }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    notes: '',
  });

  const [createContact] = useMutation(CREATE_CONTACT, {
    onCompleted: () => {
      if (typeof onSave === 'function') {
        onSave('Contact added successfully!');
      }
      handleClose();
    },
  });

  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onCompleted: () => {
      if (typeof onSave === 'function') {
        onSave('Contact updated successfully!');
      }
      handleClose();
    },
  });

  useEffect(() => {
    if (contact) {
      setFormData(contact);
    } else {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        notes: '',
      });
    }
  }, [contact]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const owner = await getUserSub();
    const cleanedData = JSON.parse(JSON.stringify({ ...formData }, removeTypename));

    if (contact) {
      updateContact({ variables: { input: { ...cleanedData, id: contact.id } } });
    } else {
      createContact({ variables: { input: cleanedData } });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-contact-form"
        aria-describedby="modal-contact-form-description"
      >
        <Box sx={style}>
          <Typography id="modal-contact-form" variant="h6" component="h2">
            {contact ? 'Edit Contact' : 'Add Contact'}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {contact ? 'Save' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactFormModal;