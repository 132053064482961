import "./instrument";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import CustomThemeProvider from './ui/CustomThemeProvider';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("index.js Rendered");

root.render(
    <ApolloProvider client={client}>
      <CustomThemeProvider>
        <Router>
          <App />
        </Router>
      </CustomThemeProvider>
    </ApolloProvider>
);