import React, { useState, useEffect, useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import { Link, Box, Button, Typography, FormControlLabel, Switch, Divider, Card, CardContent, CardActions, Grid, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import { STORE_GOOGLE_ACCESS_TOKEN, UPDATE_USER_EMAIL_AUTH } from './queries';
import { AppContext } from '../AppContext';
import GoogleIcon from '@mui/icons-material/Google';
import { useSnackbar } from '../SnackbarContext';

const Connectors = () => {
  const [storeGoogleAccessToken] = useMutation(STORE_GOOGLE_ACCESS_TOKEN);
  const [updateUserEmailAuth] = useMutation(UPDATE_USER_EMAIL_AUTH);
  const { user, refetchUser } = useContext(AppContext);
  const { showSnackbar } = useSnackbar();

  const [emailAccounts, setEmailAccounts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [emailToDisconnect, setEmailToDisconnect] = useState(null);

  useEffect(() => {
    if (user?.emailAuth) {
      let emailAuth = user.emailAuth;
      if (typeof emailAuth === 'string') {
        try {
          emailAuth = JSON.parse(emailAuth);
        } catch (error) {
          console.error("Failed to parse emailAuth:", error);
        }
      }
      if (emailAuth.gmail) {
        setEmailAccounts(emailAuth.gmail);
      } else {
        setEmailAccounts([]);
      }
    } else {
      setEmailAccounts([]);
    }
  }, [user]);

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    const { code } = tokenResponse;
    try {
      await storeGoogleAccessToken({ variables: { code: code } });
      refetchUser();
      showSnackbar({ message: 'Gmail account connected successfully!', severity: 'success' });
    } catch (error) {
      console.error("Failed to get access token:", error);
      showSnackbar({ message: 'Failed to connect Gmail account', severity: 'error' });
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
    showSnackbar({ message: 'Google login failed', severity: 'error' });
  };

  const stripTypename = (key, value) => (key === '__typename' ? undefined : value);

  const handleToggleChange = async (emailAccount, enabled) => {
    const updatedEmailAccounts = emailAccounts.map(account =>
      account.emailAccount === emailAccount ? { ...account, enabled } : account
    );
    setEmailAccounts(updatedEmailAccounts);

    const strippedEmailAccounts = JSON.parse(JSON.stringify({ gmail: updatedEmailAccounts }), stripTypename);

    try {
      await updateUserEmailAuth({
        variables: {
          input: {
            emailAuth: strippedEmailAccounts,
          },
        },
      });
      refetchUser();
      showSnackbar({ message: `Gmail account ${enabled ? 'enabled' : 'disabled'} successfully!`, severity: 'success' });
    } catch (error) {
      console.error("Failed to update email auth:", error);
      showSnackbar({ message: 'Failed to update Gmail account status', severity: 'error' });
    }
  };

  const handleCalToggleChange = async (emailAccount, calEnabled) => {
    const updatedEmailAccounts = emailAccounts.map(account =>
      account.emailAccount === emailAccount ? { ...account, calEnabled } : account
    );
    setEmailAccounts(updatedEmailAccounts);

    const strippedEmailAccounts = JSON.parse(JSON.stringify({ gmail: updatedEmailAccounts }), stripTypename);

    try {
      await updateUserEmailAuth({
        variables: {
          input: {
            emailAuth: strippedEmailAccounts,
          },
        },
      });
      refetchUser();
      showSnackbar({ message: `Gmail Calendar ${calEnabled ? 'enabled' : 'disabled'} successfully!`, severity: 'success' });
    } catch (error) {
      console.error("Failed to update email auth:", error);
      showSnackbar({ message: 'Failed to update Gmail account status', severity: 'error' });
    }
  };

  const handleSignOut = async () => {
    const updatedEmailAccounts = emailAccounts.filter(account => account.emailAccount !== emailToDisconnect);
    setEmailAccounts(updatedEmailAccounts);

    const strippedEmailAccounts = JSON.parse(JSON.stringify({ gmail: updatedEmailAccounts }), stripTypename);

    try {
      await updateUserEmailAuth({
        variables: {
          input: {
            emailAuth: strippedEmailAccounts,
          },
        },
      });
      refetchUser();
      showSnackbar({ message: 'Gmail account disconnected successfully!', severity: 'success' });
    } catch (error) {
      console.error("Failed to update email auth:", error);
      showSnackbar({ message: 'Failed to disconnect Gmail account', severity: 'error' });
    }
    setOpenDialog(false);
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
  });

  const openDisconnectDialog = (emailAccount) => {
    setEmailToDisconnect(emailAccount);
    setOpenDialog(true);
  };

  const closeDisconnectDialog = () => {
    setOpenDialog(false);
    setEmailToDisconnect(null);
  };

  return (
    <Box sx={{ padding: '0' }}>
      <Typography variant="body1" sx={{ marginBottom: '1em' }}>
      Connect your Gmail accounts to seamlessly monitor your mail for responses. You can enable or disable email accounts, view the last checked time, and easily disconnect accounts when needed. To get started, click the "Connect New Gmail Account" button below.
    </Typography>
    <Alert severity="info" sx={{ marginBottom: '1em' }}>
      <Typography variant="body2">
        By connecting your Gmail account, you agree to our <Link>Terms of Service</Link> and <Link>Privacy Policy</Link>.
      </Typography>
      <Typography variant="body2">
        SmartMail uses the Gmail API to search your email and check for responses. Your email account is securely stored and never shared with third parties. SmartMail will only work on Job Opoortunities that are Active, and not "Saved", "Withdrawn", or "Rejected".
        <strong>While the GMail connector is in "Testing" Mode, you'll need to reauthenticate to Google every 7 days. This will go away in production.</strong>
      </Typography>
    </Alert>

      
      <Grid container spacing={2}>
        {emailAccounts.map(account => (
          <Grid item xs={12} sm={6} md={4} key={account.emailAccount}>
            <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar sx={{ bgcolor: 'transparent', margin: '0 auto 1em auto' }}>
                  <GoogleIcon color="action" sx={{ width: 48, height: 48 }} />
                </Avatar>
                <Typography variant="h7">{account.emailAccount}</Typography>
                <Typography variant="body2" sx={{ marginTop: '1em' }}>
                  Last Checked: {account.last_checked ? new Date(account.last_checked).toLocaleString() : "Never"}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'space-between', padding: '1em' }}>
                <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={account.enabled}
                      onChange={(e) => handleToggleChange(account.emailAccount, e.target.checked)}
                      color="primary"
                    />
                  }
                  label={account.enabled ? "Email Enabled" : "Email Disabled"}
                  sx={{ margin: 0 }}
                /> 
                <FormControlLabel
                  control={
                    <Switch
                      checked={account.calEnabled}
                      onChange={(e) => handleCalToggleChange(account.emailAccount, e.target.checked)}
                      color="primary"
                    />
                  }
                  label={account.calEnabled ? "Calendar Enabled" : "Calendar Disabled"}
                  sx={{ margin: 0 }}
                />
                </Box>
                <Button variant="contained" color="primary" onClick={() => openDisconnectDialog(account.emailAccount)}>
                  Disconnect
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={() => login()}>
          Connect New Gmail Account
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={closeDisconnectDialog}
      >
        <DialogTitle>Disconnect Gmail Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disconnect the Gmail account {emailToDisconnect}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDisconnectDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSignOut} color="primary">
            Disconnect
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Connectors;