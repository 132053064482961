import React, { useState } from 'react';
import { useTheme, useMediaQuery, Typography, Tabs, Tab, Box, Divider } from '@mui/material';
import Profile from '../components/Profile';
import Connectors from '../components/Connectors';

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Box p={3} sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
        {children}
      </Box>
    )}
  </div>
);

const Settings = () => {
  const [value, setValue] = useState(0);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '66px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
          <Tab label="Profile" />
          <Tab label="Connectors" />
        </Tabs>
      </Box>
      {!isMobile && (
        <Box sx={{ position: 'relative' }}>
          <Divider sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '100%',
            transform: 'translateX(-50%)',
            marginLeft: '50%'
          }} />
        </Box>
      )}
      <Box sx={{ paddingTop: (isMobile ? '0px' : '1em') }}>
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Connectors />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Settings;